import React from 'react'

const TransparentButton = (props) => {
    const {text, onclick} = props
  return (
    <div>
    <button onClick={onclick} className="py-3 px-6 rounded-lg font-thicboi bg-transparent border border-white font-semibold text-sm text-white">
        <p>{text}</p>
    </button>
    </div>
  )
}

export default TransparentButton