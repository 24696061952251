import React from 'react'
import tick from '../images/check-circle.png'

import background from '../images/background.png'

const PricingMonthlyTable = () => {
  return (
    <div>
        <div className="flex flex-col md:flex-row md:items-center ">
        <div className="flex flex-col md:w-1/3">
                <div className=" hidden md:flex py-6 px-4 border invisible  border-[#30374F]">
                    <div className="flex justify-between items-center">
                        <p className="text-white">STARTUP PLAN</p>
                        <p className="bg-white px-4 py-2 rounded-full">Popular</p>
                    </div>
                    <p className="text-white flex items-center ">₹ <span className="text-4xl mx-2">400</span> per interview</p>
                    <p className="mt-4 text-white opacity-40">Basic features for up to 10 users</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>Open job positions</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>Applicants</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>AI video interviews</p>
                </div>
                <div className="hidden md:flex py-[24px] px-[32px] border  border-[#30374F] text-white">
                    <p>AI JD Creator</p>
                </div>
                <div className="hidden md:flex py-[24px] px-[32px] border  border-[#30374F] text-white">
                <p>AI resume evaluation</p>
                </div>
                <div className="hidden md:flex py-[24px] px-[32px] border  border-[#30374F] text-white">
                    <p>Soft skills evaluation</p>
                </div>
                <div className="hidden md:flex py-[24px] px-[32px] border  border-[#30374F] text-white">
                    <p>Whatsapp & SMS</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    Data Storage
                </div>
                <div className="hidden md:flex py-[24px] px-[32px] border  border-[#30374F] text-white">
                    Integrations
                </div>
            </div>
            <div className="flex flex-col md:w-1/3">
                <div className="py-6 px-4 border  border-[#30374F]">
                    <div className="flex justify-between items-center">
                        <p className="text-white">STARTUP PLAN</p>
                        <p className="bg-white px-4 py-2 rounded-full">Popular</p>
                    </div>
                    <p className="text-white flex items-center ">₹ <span className="text-4xl mx-2">400</span> per interview</p>
                    <p className="mt-4 text-white opacity-40">Basic features for up to 10 users</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>Unlimited</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>10,000</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>120</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    6 months
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
            </div>
            {/* 2nd section */}

            <div 
            style={{backgroundImage:`url(${background})`}}
            className="flex flex-col md:w-1/3 bg-local bg-right-top  bg-cover font-thicboi">
                <div className="py-8 px-4 border  border-[#30374F]">
                    <div className="flex justify-between items-center">
                        <p className="text-white">BUSINESS PLAN</p>
                    </div>
                    <p className="text-white flex items-center ">₹ <span className="text-4xl mx-2">300</span> per interview</p>
                    <p className="mt-4 text-white opacity-40">Basic features for up to 20 users</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>Unlimited</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>Unlimited</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>500</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    1 year
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
            </div>

            {/* 3rd section */}

            <div className="flex flex-col md:w-1/3">
                <div className="py-5 px-4 border  border-[#30374F]">
                    <div className="flex justify-between items-center">
                        <p className="text-white">ENTERPRISE PLAN</p>
                    </div>
                    <p className="text-white flex items-center "><span className="text-4xl mr-2">Custom</span> per interview</p>
                    <p className="mt-4 text-white opacity-40">Advanced features + unlimited users.</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>Unlimited</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>Unlimited</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <p>1000</p>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    Upto 3 years
                </div>
                <div className="hidden md:flex py-[26px] px-[32px] border  border-[#30374F] text-white">
                    <img src={tick} className='h-5'></img>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PricingMonthlyTable