import React from 'react'
import woman_waving from '../images/girl.png'
import logo from '../images/logo.png'
import small_logo from '../images/small_logo.png'
import HeroSectionLowerLeftCard from './HeroSectionLowerLeftCard'
import HeroSectionTopMostCard from './HeroSectionTopMostCard'
import {ReactTyped} from "react-typed";
import HeroSectionLowerRightCard from './HeroSectionLowerRightCard'
const HeroSectionInnerMostCard = () => {

  return (
    <div  className="md:block w-full md:w-2/3 md:scale-100  -z-40  ">
        <div data-aos="fade-up " className='border-[#FFFFFF33] border-2 bg-darkBlue'>
            <div className="md:py-4 py-2 px-4 md:px-6">
                <img src={logo} className="md:h-5 h-3 "></img>
            </div>
            <div className="bg-[#FFFFFF33] h-[0.05rem]"></div>
            <div className="md:p-4 p-[0.5rem] flex space-x-1 md:space-x-4">
                <div className="md:w-3/5 w-2/3">
                    <img src={woman_waving} className="-z-60 "></img>
                </div>
                <div className="customBorder rounded-lg p-1 md:p-4 w-1/3 md:w-2/5 flex flex-col justify-between">
                    <div className="">
                        <p className="text-center mt-4 text-[0.4rem] md:block text-white md:text-xl  font-thicboi">Live Questions</p>
                        <p className="mt-4 text-center hidden font-thicboi text-sm md:block  text-[#FFFFFF] opacity-20">Call Started | 02:35 pm</p>
                        <p className="mt-6 text-center font-thicboi hidden  text-sm text-[#FFFFFF] opacity-20">Apurva joined</p>
                        <div className="mt-4  md:flex items-end space-x-0 md:space-x-4">
                            <img src={small_logo} className="md:h-8 hidden md:block"></img>
                            <div className="bg-[#30374F] p-[10px] md:p-4 rounded-lg    font-thicboi text-[0.2rem] md:text-sm text-white">
                                <p>Can you describe a time when you had to deal with a difficult customer? <ReactTyped 
                                    strings={["how did you handle the situation, and what was the outcome?"]}
                                    typeSpeed={100}
                                    
                                    backSpeed={20}
                                    // cursorChar=""
                                    showCursor={true}/>
                                </p>
                                {/* Speed slow of other section */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-[#FFFFFF] px-1 opacity-30 md:text-base text-[0.4rem] font-thicboi rounded-full border md:px-4 mt-2 py-2">Ask any question</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="absolute scale-[0.4] md:scale-100 -right-28 -md:right-24 -top-40 md:-top-20 ">
            <div   className=" ">
                <HeroSectionTopMostCard/>
            </div>
        </div>
        <div className="absolute scale-[0.4] md:scale-100 -left-28 md:-left-20 md:-bottom-20 -bottom-40">
            <div className="">
                <HeroSectionLowerLeftCard></HeroSectionLowerLeftCard>
            </div>
        </div>
        <div className="absolute md:block hidden -right-20  -bottom-16">
            <div className="relative">
                <HeroSectionLowerRightCard/>
            </div>
        </div>
    </div>
  )
}


export default HeroSectionInnerMostCard