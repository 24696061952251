import React from 'react'
import { Link } from 'react-router-dom'

const WhiteButton = (props) => {
    const {text, to, email } = props
    const onclick = props.onclick
  return (
    <Link to = {to} state={{email: email?email:""}}>
     <button onClick={onclick}
    className="py-1 px-3 md:py-3 md:px-6 text-base rounded-lg font-thicboi bg-white text-black">
        <p>{text}</p>
    </button>
    </Link>
   
  )
}

export default WhiteButton