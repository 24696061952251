import React, { useEffect } from "react";
import invertedbackground from "../images/secondBackground.png";
import instagram from "../images/instagram.png";
import linkedin from "../images/linkedin.png";
import twitter from "../images/twitter.png";
import logo from "../images/logo.png";
import founder1 from "../images/image 7.png";
import founder2 from "../images/founder2.png";
import founder3 from "../images/founder3.png";
import Group from "../images/Group Photo.png";
import WhiteButton from "../components/WhiteButton";
import TransparentButton from "../components/TransparentButton";
import AboutusInnerMostCard from "../components/AboutusInnerMostCard";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import Header from "../components/Header";
const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-full ">
      <div className=" bg-[#0d121e] h-full bg-cover font-thicboi px-6 md:px-20">
        <Header />
        <div className="flex flex-col my-20 text-center gap-6">
          <div>
            <p className="font-thicboi text-2xl text-white">Know more</p>
            <p className=" text-[#D4C4FF] font-dmSerif italic text-7xl">
              About us
            </p>
          </div>
          <div className="w-full flex items-center justify-center ">
            <p className="text-white text-center px-2 md:w-1/2 font-extralight font-sans">
              <p>
                Northstarz is the leader in HR Technology with leading AI
                products built to help organisations and staffing agencies
                enhance their recruitment processes. Our AI is unique in that it
                not just helps identify functional fitment but actually assists
                you in identifying top talent who bring an all round skill set
              </p>
            </p>
          </div>
        </div>
        <div className="text-center flex justify-center">
          <img src={Group}></img>
        </div>
      </div>
      <div className=" bg-[#0d121e]">
        <div className=" text-white font-thicboi font-extralight text-2xl leading-9 text-center px-6 md:px-20 py-20">
          <div className=" flex flex-col items-center  gap-4">
            <span>
              <p>
                We are innovators - a team of specialists from different aspects
                of{" "}
              </p>
              <p>
                business and industries on a mission to make it easy for you to
              </p>{" "}
              <p>hire faster, better, and cost-efficiently.</p>
            </span>

            <span>
              <p>
                We strongly believe that having the right people is the most{" "}
              </p>
              <p>important bridge to constant success and innovation! Our AI</p>{" "}
              <p>
                platform works as your recruiter, but unlike human beings, it
              </p>{" "}
              <p>
                offers you a hiring solution which works 24/7, without bias and{" "}
              </p>
              <p>without boundaries.</p>
            </span>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${invertedbackground})` }}
        className="bg-local w-full  bg-cover font-thicboi flex gap-10 md:pl-40 pt-20 px-6 md:pr-10"
      >
        <div className="w-full flex flex-col items-center md:items-start gap-10 z-40 scale-90">
          <div className="font-thicboi flex flex-col items-center md:items-start gap-3">
            <span className="text-white text-center md:text-left font-bold text-4xl leading-10">
              Discover intelligence
            </span>
            <p className="text-white text-center md:text-start text-l">
              Northstarz.AI is a transformative recruitment platform designed to
              help companies identify top-tier candidates. Less than 5% of
              applicants demonstrate excellence in essential skills such as
              innovation, adaptability, and collaboration—traits that enable
              them to exceed their key result areas and drive sustainable growth
              within organizations.
              <br></br>
              <br></br>
              Our platform is developed under the guidance of experienced
              business and HR experts skilled in recognizing these critical
              competencies. To date, Northstarz.AI has analyzed and scored over
              30,000 human responses, employing multiple training layers to
              minimize bias and promote an objective recruitment process.
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <WhiteButton text={"Request a demo"} to={"/demo"} />
          </div>
        </div>
        <div className="w-full hidden md:flex scale-75">
          <div className="w-full -translate-y-10 ">
            <AboutusInnerMostCard />
          </div>
        </div>
      </div>
      <div className="bg-[#0d121e] px-6 md:px-40 py-20 flex flex-col gap-16">
        <div className="flex flex-col gap-2">
          <span className="font-thicboi text-[14px]  text-center text-[#9B8AFB]">
            LEADERSHIP
          </span>
          <span className="text-white font-thicboi text-center text-5xl font-bold">
            Our Founders
          </span>
          <span className="text-[#B9C0D4] font-thicboi font-thin text-center text-base">
            Read about us
          </span>
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <div className=" border-[1px] border-[#30374F] px-16 py-10 flex justify-between  flex-col gap-6 items-center w-full">
            <div>
              <img src={founder1}></img>
            </div>
            <div className="flex flex-col  gap-2 w-full">
              <span className=" text-white font-thicboi text-center font-semibold text-3xl">
                Avinash Singh
              </span>
              <span className="text-purple-300 font-thicboi font-semibold text-center text-[14px]">
                Co-founder & CEO
              </span>
              <p className="text-center text-[#B9C0D4] font-thicboi font-extralight text-base leading-5">
                With 20+ years as a global business leader in technology,
                Avinash excels in scaling new ventures, driving international
                expansion, and assembling high-performing teams. A tech
                enthusiast and a passionate entrepreneur, he is skilled at
                building and executing organisational strategies, disrupting
                traditional models, and consistently delivering outstanding
                results.
              </p>
            </div>
            <div className="flex gap-3 justify-center w-full">
              <div>
                <a href="https://www.linkedin.com/in/avinashvictor/">
                  <img src={linkedin}></img>
                </a>
              </div>
            </div>
          </div>
          <div className=" border-[1px] border-[#30374F] px-16 py-10 flex flex-col justify-between  gap-6 items-center w-full">
            <div>
              <img src={founder2}></img>
            </div>
            <div className="flex flex-col   gap-2 w-full">
              <span className=" text-white font-thicboi text-center font-semibold text-3xl">
                Rajiv Ranjan
              </span>
              <span className="text-purple-300 font-thicboi font-semibold text-center text-[14px]">
                Co-founder & Chief Product Officer
              </span>
              <p className="text-center text-[#B9C0D4] font-thicboi font-extralight text-base leading-5">
                Rajiv is a seasoned Human Resources leader, with 18+ years in
                international organisations spanning Technology, Banking, and
                Shared Services. He is skilled at implementing human-centric
                strategies in hiring, development and operations. His expertise
                extends to creating customer-centric HR Tech solutions which
                reflect his commitment to excellence.
              </p>
            </div>
            <div className="flex justify-center gap-3 w-full">
              <div>
                <a href="https://www.linkedin.com/in/13rajivranjan/">
                  <img src={linkedin}></img>
                </a>
              </div>
            </div>
          </div>
          <div className=" border-[1px] border-[#30374F] px-16 py-10 flex flex-col justify-between  gap-6 items-center w-full">
            <div>
              <img src={founder3}></img>
            </div>
            <div className="flex flex-col justify-between  gap-2 w-full">
              <span className=" text-white font-thicboi text-center font-semibold text-3xl">
                Saurabh Sisodia
              </span>
              <span className="text-purple-300 font-thicboi font-semibold text-center text-[14px]">
                Co-founder & CTO
              </span>
              <p className="text-center text-[#B9C0D4] font-thicboi font-extralight text-base leading-5">
                Saurabh is a seasoned technology leader with 22 years of
                experience. He comes with a prestigious educational background
                from top Indian colleges. Proficient in crafting and managing
                technology solutions and teams, he has successfully led both
                small and large teams in international organizations. Saurabh
                excels in research, enjoys building solutions from scratch, and
                has a track record of creating award-winning products.
              </p>
            </div>
            <div className="flex justify-center gap-3 w-full">
              <div>
                <a href="https://www.linkedin.com/in/sssisodia/">
                  <img src={linkedin}></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Aboutus;
