import React, { useEffect } from "react";
import logo from "../images/logo.png";
import WhiteButton from "../components/WhiteButton";
import TransparentButton from "../components/TransparentButton";
import background from "../images/termsbackground.png";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import Header from "../components/Header";
const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-full">
      <div
        style={{
          backgroundImage: `url(${background})`,
        }}
        className="bg-[#0d121e] h-full bg-cover font-thicboi px-6 md:px-20 py-10"
      >
        <Header />
        <div className="flex flex-col px-6 md:py-20 items-center text-center gap-6">
          <div className="md:w-[50%]">
            <p className="text-white font-dmSerif italic text-7xl">
              Terms & Condition
            </p>
          </div>
          <div className="md:w-[70%] flex items-center justify-center">
            <p className="text-white text-center font-extralight font-sans">
              The terms and conditions policy has basically a set of provisions
              that a customer/user must agree to in order to use a service. This
              policy should cover all aspects of the usability of your
              website/goods/services. Having this policy makes your business
              look professional and may also ask any questions/doubts that may
              arrive in the minds of the visitors who drop by your Online Store.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#0d121e] px-6 md:px-40 py-20">
        <div className="text-white w-full font-thicboi font-extralight text-[18px] leading-7 flex flex-col gap-6 px-4 py-6">
          <div>
            <h2 className="text-3xl font-bold">
              Novelio Technologies Private Limited - Northstarz.Ai
            </h2>
            <br></br>
            <h3 className="text-3xl font-bold">General Terms & Conditions</h3>
            <br></br>
            <h3 className="text-3xl font-bold">Acceptance of Terms</h3>
            <p>
              By accessing and using the Northstarz.Ai platform ("Platform")
              owned and operated by Novelio Technologies Private Limited
              ("Novelio," "we," "us," or "our"), you agree to be bound by the
              terms and conditions of this Agreement ("Terms"). Your use of the
              Platform is subject to compliance with these Terms and any
              applicable policies, including but not limited to the Privacy
              Policy, Cancellation and Refund Policy, and any other relevant
              documents incorporated herein by reference.
            </p>
            <p>
              If you are accessing or using the Platform on behalf of a
              corporation, partnership, or any other entity, you represent and
              warrant that you have the authority to accept these Terms on
              behalf of such entity, and your use of the Platform constitutes
              acceptance by that entity. Services and Subscriptions
            </p>
            <p>
              The Northstarz.Ai services ("Services") provided by Novelio are
              governed by these Terms, including the applicable policies
              available at https://www.interviewguru.co ("Website"). By
              contacting Novelio for services, availing the services, or
              registering on the Platform, you, as the Subscriber or Customer
              ("you" or "user"), acknowledge and agree to abide by these Terms
              and the Policies.
            </p>
            <p>
              This Agreement becomes effective upon your acceptance and governs
              the relationship between you and Novelio. Each party, Novelio and
              the Subscriber, is referred to as a "party," and collectively they
              are referred to as the "parties."{" "}
            </p>
          </div>

          <div>
            <h3 className="text-3xl font-bold">Definitions</h3>
            <ul className="list-disc pl-5 mt-4 space-y-2">
              <li>
                <strong>Affiliates</strong>: Any entity directly or indirectly
                controlled by, controlling, or under common control with
                Novelio, where "control" signifies ownership or control of over
                50% of the voting interests.
              </li>
              <li>
                <strong>Authorised User</strong>: An individual user for whom a
                user license has been purchased by the Subscriber, with unique
                user credentials to access the Northstarz.Ai platform.
                Authorised Users may include employees, contractors, or
                consultants of the Subscriber or its Affiliates.
              </li>
              <li>
                <strong>Confidential Information</strong>: All information
                disclosed by one party ("Disclosing Party") to the other party
                ("Receiving Party"), whether oral or written, designated as
                confidential or reasonably understood to be confidential.
                Novelio’s Confidential Information includes the terms of this
                Agreement and all Invoices.
              </li>
              <li>
                <strong>Subscriber Data</strong>: Electronic data submitted to
                the Northstarz.Ai platform by the Subscriber.
              </li>
              <li>
                <strong>Documentation</strong>: User manuals and documentation
                provided by Novelio detailing the features, functionalities, and
                operation of the Northstarz.Ai platform.
              </li>
              <li>
                <strong>Employee</strong> or <strong>Worker</strong>: Includes
                employees, consultants, contingent workers, independent
                contractors, and retirees of the Subscriber and its Affiliates.
              </li>
              <li>
                <strong>Improvements</strong>: All updates, enhancements, error
                corrections, bug fixes, release notes, upgrades, and changes to
                the Service and Documentation, developed by Novelio and made
                available without an additional charge.
              </li>
              <li>
                <strong>Intellectual Property</strong> or <strong>IP</strong>:
                All intellectual property, registered or not, such as patents,
                designs, literary works, artistic works, audio, video, computer
                programs, copyrights, trademarks, trade secrets, and any
                materials protected under intellectual property rights.
              </li>
              <li>
                <strong>Law</strong>: Any local, state, national, and/or foreign
                law, treaties, and/or regulations applicable to the respective
                party.
              </li>
              <li>
                <strong>Malicious Code</strong>: Viruses, worms, time bombs,
                Trojan horses, and other malicious code, files, scripts, agents,
                bots, or programs.
              </li>
              <li>
                <strong>Order Form</strong>: The ordering documents fully
                executed pursuant to this Agreement, under which the Subscriber
                subscribes to the Service.
              </li>
              <li>
                <strong>Personal Data</strong>: As defined in Exhibit 3.
              </li>
              <li>
                <strong>Production</strong>: The Subscriber's use of or
                Novelio's written verification of the Service's availability for
                specific purposes.
              </li>
              <li>
                <strong>Security Breach</strong>: Any unauthorized use, loss,
                access to, or disclosure of Subscriber Data, as defined in
                applicable Law.
              </li>
              <li>
                <strong>Northstarz.Ai Platform</strong>: Novelio's
                software-as-a-service applications as described in the
                Documentation and subscribed to under an Order Form.
              </li>
              <li>
                <strong>Non-Northstarz.Ai Services</strong>: Third-party
                applications, services, software, networks, systems, websites,
                or databases integrated with the Northstarz.Ai platform.
              </li>
              <li>
                <strong>Invoice</strong>: The document evidencing a subscription
                to Northstarz.Ai Services.
              </li>
              <li>
                <strong>Subscription Period(s)</strong>: The duration of
                validity of each fee-based subscription plan purchased by the
                Subscriber.
              </li>
              <li>
                <strong>Usage Limits</strong>: The limits on the use of the
                Northstarz.Ai Platform corresponding to the fee-based
                subscription plan purchased by the Subscriber.
              </li>
              <li>
                <strong>Taxes</strong>: All taxes, duties, levies, imposts,
                fines, or similar governmental assessments.
              </li>
              <li>
                <strong>Terms of Service</strong>: The terms and conditions for
                accessing and using the Northstarz.Ai platform, as modified from
                time to time.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-3xl font-bold">Confidentiality</h3>
            <p>
              The parties acknowledge and agree that all information exchanged
              between them, including but not limited to Confidential
              Information, shall be kept confidential and used solely for the
              purpose of this Agreement.
            </p>
            <p>
              Confidential Information does not include information that is or
              becomes public knowledge without a breach of obligation, was known
              to the receiving party prior to disclosure, is received from a
              third party without breach of any obligation, or is independently
              developed by the receiving party without the use of the disclosing
              party's Confidential Information.
            </p>

            <h3 className="text-3xl font-bold mt-6">Intellectual Property</h3>
            <p>
              All Intellectual Property rights in the Northstarz.Ai platform,
              including but not limited to patents, copyrights, trademarks, and
              trade secrets, remain the exclusive property of Novelio
              Technologies Private Limited.
            </p>
            <p>
              The Subscriber acknowledges and agrees that it does not acquire
              any ownership rights in the Intellectual Property of Novelio
              Technologies Private Limited by using the Northstarz.Ai platform.
            </p>

            <h3 className="text-3xl font-bold mt-6">
              Subscriber Data and Privacy
            </h3>
            <p>
              Novelio shall take reasonable measures to safeguard Subscriber
              Data and comply with applicable data protection laws.
            </p>
            <p>
              The Subscriber grants Novelio the right to use Subscriber Data for
              the sole purpose of providing the Northstarz.Ai services.
            </p>
            <p>
              Personal Data is processed in accordance with the terms set forth
              in Exhibit 3.
            </p>

            <h3 className="text-3xl font-bold mt-6">
              Service Level Agreement (SLA) and Security
            </h3>
            <p>
              The Service Level Agreement and Security Agreement are integral
              parts of this Agreement and are incorporated herein by reference.
              Novelio shall make reasonable efforts to ensure the security and
              availability of the Northstarz.Ai platform.
            </p>

            <h3 className="text-3xl font-bold mt-6">Subscription and Fees</h3>
            <p>
              The Subscriber agrees to pay the fees specified in the Invoice for
              the selected subscription plan. All fees are exclusive of Taxes,
              and the Subscriber is responsible for payment of any applicable
              Taxes.
            </p>

            <h3 className="text-3xl font-bold mt-6">Termination</h3>
            <p>
              Either party may terminate this Agreement for cause upon written
              notice if the other party breaches any material term or condition.
              Upon termination, the Subscriber's access to the Northstarz.Ai
              platform is terminated, and Novelio may permanently delete
              Subscriber Data.
            </p>

            <h3 className="text-3xl font-bold mt-6">
              Governing Law and Dispute Resolution
            </h3>
            <p>
              This Agreement shall be governed by and construed strictly in
              accordance with the laws of India (excluding the rules governing
              conflict of laws). Any dispute arising out of or resulting from
              this Agreement shall be subject to the exclusive jurisdiction of
              courts in Delhi-NCR to the exclusion of all other courts.
            </p>

            <h3 className="text-3xl font-bold mt-6">Rights Granted</h3>
            <p>
              Subject to the terms and conditions of this Agreement, Novelio
              will make the Northstarz.Ai Platform available to Subscribers for
              the Subscription Period as outlined in the Invoice. Novelio grants
              the Subscriber a revocable, non-exclusive, non-transferable right,
              and limited licence to access, use, and, where applicable,
              download the Northstarz.Ai Platform during the Subscription Period
              for internal business purposes. In the event of exceeding the
              Usage Limits or functionalities within the Northstarz.Ai Platform,
              the Subscriber may purchase additional quantities by making
              payment(s) for such excess usage.
            </p>
          </div>

          <div>
            <div className="terms-container">
              <h1 className="text-3xl font-bold mb-4">Usage Restrictions</h1>
              <ul className="list-disc pl-5">
                <li>
                  Copy, modify, create derivative works, or attempt unauthorised
                  access to the Northstarz.Ai Platform.
                </li>
                <li>
                  Attempt to disassemble, reverse engineer, or decompile the
                  Northstarz.Ai Platform, except as permitted under applicable
                  law.
                </li>
                <li>
                  Use the Northstarz.Ai Platform on behalf of a third party or
                  as part of a service bureau or business process service.
                </li>
                <li>
                  Interfere with the integrity, security, or performance of the
                  Northstarz.Ai Platform.
                </li>
                <li>
                  Sell, resell, licence, sublicense, rent, lease, transfer,
                  assign, or make the Northstarz.Ai Platform available to any
                  third party without an Authorised User subscription.
                </li>
                <li>
                  Use the Northstarz.Ai Platform to store or transmit malicious
                  code or material that violates any third-party rights.
                </li>
                <li>
                  Permit direct or indirect access circumventing Usage Limits.
                </li>
                <li>
                  Use the Northstarz.Ai Platform in a manner that could harm
                  Northstarz.Ai's servers, networks, computer systems, or
                  resources.
                </li>
                <li>
                  Share or use Authorised User licences with more than one
                  individual, except by reassigning the licence to a new user.
                </li>
                <li>
                  Remove or obscure proprietary notices contained in the
                  Northstarz.Ai Platform.
                </li>
                <li>
                  Gain unauthorised access to the Northstarz.Ai Platform or use
                  it for competitive or benchmarking purposes.
                </li>
              </ul>

              <h2 className="text-3xl font-bold mt-6 mb-4">
                Subscriber Responsibilities
              </h2>
              <ul className="list-disc pl-5">
                <li>
                  Provide accurate information for access and use of the
                  Northstarz.Ai Platform.
                </li>
                <li>
                  Ensure Authorised Users' compliance with the Agreement,
                  Documentation, and Invoice.
                </li>
                <li>
                  Maintain the accuracy, quality, and legality of Subscriber
                  Data.
                </li>
                <li>
                  Use commercially reasonable efforts to prevent unauthorised
                  access.
                </li>
                <li>
                  Utilise the Northstarz.Ai Platform in accordance with this
                  Agreement and applicable laws.
                </li>
                <li>
                  Take responsibility for all activities under the Subscriber's
                  account.
                </li>
                <li>Comply with all applicable laws and regulations.</li>
              </ul>

              <h2 className="text-3xl font-bold mt-6 mb-4">
                Fees and Payments
              </h2>
              <ul className="list-disc pl-5">
                <li>
                  The Subscriber will pay the fees specified in the applicable
                  Invoice. All payment obligations are non-cancellable and
                  non-refundable, irrespective of active usage.
                </li>
                <li>
                  Payments for Subscription Periods shall be made through online
                  banking facilities. The Subscription Period begins upon
                  receipt of payment or an acceptable purchase order.
                </li>
                <li>
                  Undisputed overdue payments bear interest. Non-payment
                  constitutes a material breach, allowing Novelio to block or
                  suspend access until payment or terminate the Agreement.
                </li>
                <li>
                  Subscriber must dispute invoices within five (5) business
                  days. Disputes do not constitute default if Subscriber
                  cooperates diligently and remits payment for undisputed
                  amounts.
                </li>
                <li>
                  Subscriber is responsible for paying Taxes in addition to
                  applicable fees. Withholding Taxes must be paid directly to
                  the government, with a tax certificate provided to Novelio
                  within 100 days.
                </li>
                <li>
                  Novelio reserves the right to unilaterally determine and
                  modify pricing, with agreed pricing remaining in effect during
                  the term specified in the Invoice.
                </li>
              </ul>
            </div>

            <div className="availability-support-container">
              <h1 className="text-xl font-bold mb-4">
                Availability and Technical Support
              </h1>
              <p>
                Novelio will make the Northstarz.Ai Platform available 24/7,
                subject to Scheduled/Pa Downtime and Force Majeure Events.
              </p>
              <p>
                Northstarz.Ai Platform will have a Monthly Uptime Percentage of
                99%.
              </p>
              <p>
                Periodically, Novelio introduces new features in the
                Northstarz.Ai Platform with enhanced functionality. Features and
                functionality will be made available as part of a major feature
                release (“Feature Release”) or as part of weekly service updates
                (“Service Updates”).
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">
                Calculation of Northstarz.Ai SLA Service Credit
              </h2>
              <table className="table-auto w-full border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <th className="border border-gray-300 px-4 py-2">Uptime</th>
                    <th className="border border-gray-300 px-4 py-2">
                      Compensation (% of Monthly Subscription Fees)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">99%</td>
                    <td className="border border-gray-300 px-4 py-2">5%</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">
                      &lt;99%
                    </td>
                    <td className="border border-gray-300 px-4 py-2">15%</td>
                  </tr>
                </tbody>
              </table>

              <p className="mt-4">
                In order to receive any of the Northstarz.Ai SLA Service Credits
                described above, Subscriber must notify Novelio within ten (10)
                days from the time Subscriber becomes eligible to receive a
                Northstarz.Ai SLA Service Credit. Failure to comply with this
                requirement will result in forfeiture of Subscriber’s right to
                receive a Service Credit.
              </p>
              <p>
                Northstarz.Ai SLA Service Credits will not be exchanged for, or
                converted to, monetary compensation.
              </p>
              <p>
                Subscriber’s sole and exclusive remedy for Novelio’s failure to
                meet the uptime commitment is to receive Interview SLA Service
                Credit.
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">
                Support for Functionality
              </h2>
              <p>
                Novelio will provide support for the functionality delivered by
                Northstarz.Ai Platform. However, concerning any other
                functionality, issues, errors in the Northstarz.Ai Platform
                arising from problems, errors, or modifications in Subscriber's
                information systems, customizations, and/or third-party products
                or services, Novelio may offer assistance to Subscriber and its
                third-party providers in identifying and resolving such issues
                or errors.
              </p>
              <p>
                Instances where obligations or commitments under this SLA are
                not fulfilled due to (i) acts or omissions by the Subscriber,
                and (ii) events of force majeure, will be excused.
              </p>
            </div>

            <div className="privacy-security-container">
              <h1 className="text-xl font-bold mb-4">Privacy and Security</h1>
              <p>
                Novelio processes Personal Information in compliance with
                applicable legal requirements, as outlined in Exhibit 3.
              </p>
              <p>
                Novelio maintains industry-standard safeguards for security,
                confidentiality, and integrity of Subscriber Data, detailed in
                Exhibit 1.
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">
                Proprietary Rights and Licences
              </h2>
              <p>
                Novelio retains all rights, title, and interest in the
                Northstarz.Ai Platform and Documentation. Subscriber agrees any
                other use constitutes a material breach and infringement.
              </p>
              <p>
                Subscriber grants Novelio a licence to use and incorporate any
                feedback provided into the Northstarz.Ai Platform.
              </p>
              <p>
                Novelio has a licence to use Subscriber Input without obligation
                to make it an Improvement.
              </p>
              <p>
                Novelio has exclusive rights to use Aggregated Data for business
                purposes, ensuring individual or specific data identity remains
                confidential.
              </p>
              <p>
                Northstarz.Ai's name usage requires prior written permission.
                Northstarz.Ai may use Subscriber involvement in credentials,
                proposals, and publicity material.
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">Confidentiality</h2>
              <p>
                The Receiving Party must use reasonable care to protect
                Confidential Information, limiting access to authorised
                personnel. Copies shall be returned or destroyed upon request or
                termination.
              </p>
              <p>
                The Receiving Party may disclose Confidential Information as
                required by law, providing notice to the Disclosing Party.
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">
                Representations, Warranties, and Disclaimers
              </h2>
              <p>
                Each party represents its validity and authority to enter into
                the Agreement.
              </p>
              <p>
                Novelio warrants the Northstarz.Ai Platform's performance and
                security safeguards. In case of breach, Subscriber has remedies
                as outlined in the Agreement.
              </p>
              <p>
                Northstarz.Ai Platform is provided "as is," and Novelio
                disclaims all warranties, except those explicitly stated.
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">Indemnification</h2>
              <p>
                Northstarz.Ai, a platform provided by Novelio Technologies
                Private Limited, shall defend the Subscriber, at Northstarz.Ai’s
                expense, from claims, demands, suits, or proceedings made or
                brought against the Subscriber by a third party ("Claims")
                alleging that the use of the Northstarz.Ai Platform as
                contemplated hereunder infringes such third party’s Intellectual
                Property Rights. Northstarz.Ai shall indemnify and hold the
                Subscriber harmless against any loss, damage, or costs finally
                awarded or entered into in settlement (including, without
                limitation, reasonable attorneys' fees) ("Losses").
              </p>
              <p>
                This indemnification is subject to the following conditions:
              </p>
              <ul className="list-disc pl-5">
                <li>
                  The Subscriber must promptly give written notice of the Claim
                  to Northstarz.Ai, with any delay not relieving Northstarz.Ai
                  of its obligations under this section except to the extent
                  that Northstarz.Ai is prejudiced by such delay.
                </li>
                <li>
                  The Subscriber must give Northstarz.Ai sole control of the
                  defence and settlement of the Claim (although Northstarz.Ai
                  may not settle any Claim unless it unconditionally releases
                  the Subscriber of all liability).
                </li>
                <li>
                  The Subscriber must provide to Northstarz.Ai, at
                  Northstarz.Ai's cost, all reasonable assistance.
                </li>
              </ul>
            </div>

            <div className="limitation-of-liability-container">
              <h1 className="text-xl font-bold mb-4">
                Limitation of Liability
              </h1>
              <p>
                Under no circumstances and under no legal theory, whether tort,
                contract, product liability, negligence, or otherwise, shall
                Northstarz.Ai or its affiliates be liable to the Subscriber or
                any other affiliate or third party for any lost profits, lost
                sales or lost revenue, loss of data, business interruption, loss
                of goodwill, or for any indirect, special, incidental,
                exemplary, consequential, or punitive damages, even if a party
                or its affiliates have been advised of the possibility of such
                damages.
              </p>
              <p>
                In no event shall the liability of either party to the other
                party or its affiliates for any claim or action arising out of
                this Agreement exceed the value of 10% of the aggregate of all
                amounts paid by the Subscriber to Northstarz.Ai in the twelve
                (12) months preceding the first event giving rise to such claim
                or action.
              </p>
              <p>
                The limitations specified herein will not limit the Subscriber’s
                obligation to pay fees in accordance with this Agreement.
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">
                Term of Termination
              </h2>

              <h3 className="text-xl font-bold">Term</h3>
              <p>
                The term of this Agreement shall commence on the Effective Date
                and shall thereafter continue for the duration of the
                Subscription Period of the relevant Invoice, unless terminated
                in accordance with the provisions of this Section. Except as
                otherwise specified in the Agreement or Invoice, subscriptions
                will automatically renew for additional terms equivalent to the
                expiring Subscription Period.
              </p>

              <h3 className="text-xl font-bold mt-4">Termination for Cause</h3>
              <p>
                A party may terminate this Agreement for cause: (i) upon 30
                days' written notice to the other party of a material breach if
                such breach remains uncured at the expiration of such period, or
                (ii) if the other party becomes the subject of a petition in
                bankruptcy or any other proceeding relating to insolvency,
                receivership, liquidation, or assignment for the benefit of the
                creditors.
              </p>

              <h3 className="text-xl font-bold mt-4">
                Termination by Northstarz.Ai
              </h3>
              <p>
                Northstarz.Ai shall be entitled to terminate this Agreement
                forthwith upon giving written notice of thirty (30) days to the
                Subscriber if it:
              </p>
              <ul className="list-disc pl-5">
                <li>
                  Enters an agreement with creditors without authorization from
                  Northstarz.Ai and/or steps have been taken for its winding up
                  (other than for the purposes of bona fide reconstruction);
                </li>
                <li>
                  Has reasonable grounds to suspect that it has participated in
                  illegal practices and/or acts or has been charged in a court
                  of law for acts prejudicial to the interests of Northstarz.Ai;
                </li>
                <li>
                  Commits misconduct, fraudulent, dishonest, undisciplined
                  conduct, or breach of integrity or embezzlement or
                  misappropriation or misuse or causing damage to the Software
                  and other property of Northstarz.Ai;
                </li>
                <li>
                  Misrepresents, makes false statements, and breaches the
                  representations and warranties under the Agreement;
                </li>
                <li>Ceases or threatens to cease to carry on business.</li>
              </ul>

              <h3 className="text-xl font-bold mt-4">
                Termination for Convenience
              </h3>
              <p>
                Notwithstanding any other provision in this Agreement,
                Northstarz.Ai shall, at its absolute discretion, be entitled to
                terminate this Agreement without providing reasons by giving at
                least 30 (thirty) days prior written notice to the other Party.
              </p>

              <h3 className="text-xl font-bold mt-4">Refund</h3>
              <p>
                Upon termination for cause by the Subscriber, Northstarz.Ai
                shall refund the Subscriber any prepaid fees covering the unused
                portion of the Subscription Period. Upon any termination for
                cause by Northstarz.Ai, the Subscriber shall expedite all
                payments due to Northstarz.Ai, and in no event will the
                termination of this Agreement relieve the Subscriber of its
                obligation to pay any fees due to Northstarz.Ai. In the event
                the Subscriber terminates the Agreement except as mentioned in
                Section 11.2 of the Agreement, Northstarz.Ai is under no
                obligation to refund the fees paid by the Subscriber.
              </p>

              <h3 className="text-xl font-bold mt-4">
                Retrieval of Subscriber Data
              </h3>
              <p>
                Upon the Subscriber’s written request made on or before the
                expiration or termination of the Agreement, Northstarz.Ai will
                provide the Subscriber limited access to the Northstarz.Ai
                Platform for up to thirty (30) days, at no additional cost,
                solely for the purpose of retrieving Subscriber Data. Subject to
                such a thirty-day period and Northstarz.Ai’s legal obligations,
                Northstarz.Ai has no obligation to maintain or provide any
                Subscriber Data and may, unless legally prohibited, delete
                Subscriber Data.
              </p>
              <p>
                However, Northstarz.Ai will not be required to remove copies of
                the Subscriber Data from its backup media and servers until such
                time as the backup copies are scheduled to be deleted.
              </p>
            </div>

            <div className="general-and-surviving-provisions-container">
              <h1 className="text-xl font-bold mb-4">Surviving Provisions</h1>
              <p>
                Sections "Confidentiality," "Fees and Payments," "Warranty
                Disclaimers," "Limitation of Liability," "Indemnification,"
                "Termination," "Surviving Provisions," and "General" shall
                survive termination of this Agreement.
              </p>

              <h2 className="text-3xl font-bold mt-6 mb-4">General</h2>

              <h3 className="text-xl font-bold">
                Applicability of Terms of Service
              </h3>
              <p>
                The Subscriber understands that, in addition to the terms of
                this Agreement, Northstarz.Ai's Terms of Service will apply to
                the Subscriber's access and use of the Northstarz.Ai Platform.
                In the event of any conflict between this Agreement and the
                Terms of Service, the terms of this Agreement shall prevail.
              </p>

              <h3 className="text-xl font-bold mt-4">Entire Agreement</h3>
              <p>
                This Agreement, including the Exhibits attached hereto and the
                Terms of Service, constitutes the entire agreement between the
                parties with respect to the subject matter of this Agreement and
                supersedes any and all prior and contemporaneous agreements,
                negotiations, correspondence, understandings, and communications
                between the parties, whether written or oral, concerning the
                subject matter hereof.
              </p>

              <h3 className="text-xl font-bold mt-4">Amendment</h3>
              <p>
                No changes, modifications, or amendments of any nature made to
                this Agreement shall be valid unless evidenced in writing and
                signed for and on behalf of both parties by their respective
                authorised representatives.
              </p>

              <h3 className="text-xl font-bold mt-4">Notices</h3>
              <p>
                All notices required under this Agreement shall be in writing
                and shall be sent to the respective address set forth below. Any
                such notice may be delivered by hand, by overnight courier, by
                registered post or certified mail with return receipt requested,
                or by electronic mail to the person to whom such notice is to be
                sent as per the terms of this Agreement.
              </p>
              <ul className="list-disc pl-5">
                <li>
                  Such notice shall be deemed to have been received: (i) by hand
                  delivery, at the time of delivery;
                </li>
                <li>
                  (ii) by overnight courier, on the succeeding business day;
                </li>
                <li>
                  (iii) by registered post or certified mail, on the date marked
                  in proof of receipt;
                </li>
                <li>(iv) by electronic mail, when sent.</li>
              </ul>
              <p>
                All notices shall be sent to: Legal Team on{" "}
                <a
                  href="mailto:support@northstarz.ai"
                  className="text-blue-600"
                >
                  support@northstarz.ai
                </a>
                .
              </p>

              <h3 className="text-xl font-bold mt-4">
                Relationship of the Parties
              </h3>
              <p>
                The parties are independent contractors. This Agreement does not
                create a partnership, franchise, joint venture, agency,
                fiduciary, or employment relationship between the parties.
                Neither party shall have the power to bind the other or incur
                obligations on the other party's behalf without the other
                party's written consent.
              </p>

              <h3 className="text-xl font-bold mt-4">Assignment</h3>
              <p>
                Neither party shall assign any of its rights or obligations
                hereunder, whether by operation of law or otherwise, without the
                prior written consent of the other party (which consent shall
                not be unreasonably withheld). Any attempt by a party to assign
                its rights or obligations under this Agreement other than as
                permitted by this section shall be void and of no effect.
              </p>
              <p>
                Subject to the foregoing, this Agreement shall bind and inure to
                the benefit of the parties, their respective successors, and
                permitted assigns.
              </p>

              <h3 className="text-xl font-bold mt-4">Affairs of the Parties</h3>
              <p>
                It has been explicitly agreed between the Parties that at any
                time within the term of this Agreement, if the Subscriber
                undergoes one of the following, including the sale of the
                company/entity, then the Subscriber shall have the sole
                unconditional rights, among others, to:
              </p>
              <ul className="list-disc pl-5">
                <li>Change in the management;</li>
                <li>
                  Change in the corporate name or brand name or trademark;
                </li>
                <li>Restructuring;</li>
                <li>Acquisition and merger;</li>
                <li>Any Private Equity or Loan infusion into the Party.</li>
              </ul>
              <p>
                Northstarz.Ai will not interfere or raise any objections under
                the above circumstances, provided that the Subscriber shall
                ensure that the rights of Northstarz.Ai under this Agreement are
                not adversely affected or curtailed by such an event. The
                existence of the Agreement and the rights of Northstarz.Ai under
                this Agreement shall not be affected in any manner, and the
                Subscriber shall ensure the same terms and conditions are
                carried through the Term of the Agreement.
              </p>
              <p>
                If the Agreement terminates or any rights of Northstarz.Ai are
                adversely affected due to any of the above circumstances, then
                the defaulting party, i.e., the Subscriber shall indemnify
                Northstarz.Ai and compensate it for any loss or expenditure that
                Northstarz.Ai incurs.
              </p>

              <h3 className="text-xl font-bold mt-4">
                No Third-Party Beneficiaries
              </h3>
              <p>
                The provisions of this Agreement shall be binding and inure
                solely to the benefit of the parties, their successors, and
                permitted assigns. Nothing herein, whether express or implied,
                will confer any right, benefit, or remedy upon any person or
                entity other than the parties, their successors, and permitted
                assigns.
              </p>

              <h3 className="text-xl font-bold mt-4">Force Majeure</h3>
              <p>
                No Party shall be liable to the other if, and to the extent,
                that the performance or delay in performance of any of its
                obligations under this Agreement is prevented, restricted,
                delayed, or interfered with due to circumstances beyond the
                reasonable control of such Party, including but not limited to
                Government legislations, fires, floods, explosions, epidemics,
                accidents, acts of God, wars, riots, strikes, lockouts, or other
                concerted acts of workmen, acts of Government.
              </p>
              <p>
                The Party claiming an event of force majeure shall promptly
                notify the other Party in writing and provide full particulars
                of the cause or event and the date of the first occurrence
                thereof, as soon as possible after the event and also keep the
                other Party informed of any further developments.
              </p>
              <p>
                The Party so affected shall use its best efforts to remove the
                cause of non-performance, and the Parties shall resume
                performance as soon as such cause is removed.
              </p>

              <h3 className="text-xl font-bold mt-4">Severability</h3>
              <p>
                Any provision of this Agreement, which is prohibited or
                unenforceable in any jurisdiction, shall, as to such
                jurisdiction, be ineffective to the extent of such prohibition
                or unenforceability without invalidating the remaining
                provisions hereof or affecting the validity or enforceability of
                such provision in any other jurisdiction. Accordingly, this
                Agreement shall be construed as if such a portion had not been
                inserted, and the remaining provisions of this Agreement shall
                remain in full force and effect.
              </p>

              <h3 className="text-xl font-bold mt-4">Waiver</h3>
              <p>
                Except as otherwise provided in this Agreement, failure on the
                part of either Party to exercise any right hereunder or to
                insist upon strict compliance by the other Party with any of the
                terms, covenants, or conditions hereof shall not be deemed a
                waiver of such right, term, covenant, or condition.
              </p>

              <h3 className="text-xl font-bold mt-4">Interpretation</h3>
              <p>
                No provision of this Agreement shall be construed against one
                party by reason of being deemed the "author" of the Agreement.
                The headings used in this Agreement are for convenience only and
                shall not affect the interpretation of the terms of this
                Agreement.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold mb-4">Appendix</h2>
              <h3 className="text-xl font-semibold mb-3">Exhibit 1</h3>
              <h4 className="text-lg font-medium mb-2">SECURITY INFORMATION</h4>
              <p className="mb-3">
                Your employee information is consistently safeguarded and
                readily accessible at all times. Every facet of our security
                procedures, including storing, transferring, accessing, backing
                up, monitoring, testing, and reviewing, is meticulously designed
                to adhere to industry best practices.
              </p>
              <h4 className="text-lg font-medium mb-2">
                Data Privacy and Data Access
              </h4>
              <p className="mb-3">
                Our commitment to a secure and trustworthy environment for your
                subscription data is unwavering. To achieve this, we employ a
                cross-functional approach with initiatives both comprehensive
                and meticulous. The following outlines the key components of our
                privacy and security protocols.
              </p>
              <ul className="list-disc pl-5 mb-3">
                <li>Restricted access to Databases and data storages</li>
                <li>Restricted support personnel access</li>
                <li>No local or on-premise storage of data</li>
              </ul>

              <h4 className="text-lg font-medium mb-2">GDPR Compliance</h4>
              <p className="mb-3">
                Novelio is committed to maintaining compliance with the EU's
                General Data Protection Regulation (GDPR), ensuring that our
                product features, corporate protocols, and legal documents
                facilitate compliance for our users and customers.
              </p>

              <h4 className="text-lg font-medium mb-2">Application Security</h4>
              <ul className="list-disc pl-5 mb-3">
                <li>In-transit Encryption</li>
                <li>Web Application and Network Firewalls</li>
                <li>Software Development Lifecycle (SDLC) Security</li>
                <li>Datacenter Protections</li>
              </ul>

              <h4 className="text-lg font-medium mb-2">Patch Management</h4>
              <p className="mb-3">
                Our patch management process identifies and addresses missing
                patches within the product infrastructure. Server-level
                instrumentation ensures that tracked software packages use the
                appropriate versions.
              </p>

              <h4 className="text-lg font-medium mb-2">
                Security Incident Response
              </h4>
              <p className="mb-3">
                Novelio's security incident process flows and investigation data
                sources are pre-defined during recurring preparation activities
                and exercises. We adhere to standard incident response process
                structures to ensure the right steps are taken at the right
                time.
              </p>

              <h4 className="text-lg font-medium mb-2">
                Audits, Vulnerability Assessment & Penetration Testing
              </h4>
              <h4 className="text-lg font-medium mb-2">
                Vulnerability Assessment
              </h4>
              <p className="mb-3">
                Novelio conducts recurring vulnerability assessments, including
                static code analysis and infrastructure vulnerability scans, to
                identify and address potential weaknesses. Our internal and
                third-party assessments ensure that our products and
                infrastructure meet industry security standards.
              </p>

              <h4 className="text-lg font-medium mb-2">Penetration Testing</h4>
              <p className="mb-3">
                We leverage third-party penetration testing firms multiple times
                a year to assess Novelio products and product infrastructure.
                These tests simulate real-world attacks to identify and mitigate
                any vulnerabilities in our systems before they can be exploited.
              </p>

              <h4 className="text-lg font-medium mb-2">
                External Audit & Certification
              </h4>
              <p className="mb-3">
                Regular external audits and certifications are conducted by
                Novelio to ensure continued adherence to security standards.
                These audits help us maintain compliance with industry
                regulations and provide transparency regarding our security
                practices.
              </p>

              <h4 className="text-lg font-medium mb-2">
                Resiliency and Availability
              </h4>
              <ul className="list-disc pl-5 mb-3">
                <li>
                  <strong>99% Uptime</strong> - Novelio consistently maintains
                  an availability rate above 99%, ensuring that our platform is
                  always available and operational for our customers.
                </li>
                <li>
                  <strong>Data Redundancy</strong> - Customer data is 100%
                  backed up to multiple online replicas with additional
                  snapshots to ensure its availability even in the event of a
                  failure.
                </li>
              </ul>

              <h4 className="text-lg font-medium mb-2">24x7x365 Monitoring</h4>
              <p className="mb-3">
                Our product and operations team continually monitor application,
                software, and infrastructure behavior using proprietary and
                industry-recognized solutions. This proactive approach allows us
                to detect and resolve any issues before they impact users.
              </p>

              <h4 className="text-lg font-medium mb-2">
                Data Center Redundancy
              </h4>
              <p className="mb-3">
                Novelio ensures multiple failover instances are in place to
                prevent outages from single points of failure. This guarantees
                that even in the event of a localized failure, services will
                remain operational without any disruptions.
              </p>

              <h4 className="text-lg font-medium mb-2">Disaster Recovery</h4>
              <p className="mb-3">
                Robust controls are implemented for data and application code
                recovery, with a Recovery Point Objective (RPO) and Recovery
                Time Objective (RTO) of 5 seconds and 1 hour, respectively, for
                data within the same geography. In the event of a natural
                disaster affecting different geographies, the RPO and RTO are 12
                hours and 1 hour, respectively. Additionally, Novelio provides a
                45-day point-in-time restoration feature that allows restoration
                to any desired date and time within the last 45 days.
              </p>
            </div>

            <div className="pt-6 space-y-6">
              <h1 className="text-3xl font-bold">
                Exhibit 2: SERVICE LEVEL AVAILABILITY
              </h1>
              <p className="text-lg">
                This Exhibit constitutes the Service Level Availability Policy
                ("SLA") of Novelio for its Subscribers, and the terms herein
                shall have the same meaning as defined in the Master
                Subscription Agreement.
              </p>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">Definitions</h2>
                <div className="space-y-2">
                  <p className="font-semibold">1. Downtime:</p>
                  <p>
                    Refers to the incapacity to access the Northstarz.Ai
                    Platform due to a Qualifying Fault. Measurement relies on
                    Novelio’s monitoring tools assessing the availability of the
                    Northstarz.Ai Platform.
                  </p>
                  <p className="font-semibold">2. Qualifying Fault:</p>
                  <p>
                    Includes server-side errors and reachability errors
                    attributable to the Northstarz.Ai Platform.
                  </p>
                  <p className="font-semibold">3. Downtime Period:</p>
                  <p>
                    Denotes ten (10) or more consecutive minutes of Downtime.
                    Intermittent Downtime for less than ten minutes will not be
                    considered within any Downtime Periods.
                  </p>
                  <p className="font-semibold">4. Monthly Uptime:</p>
                  <p>
                    Represents the total number of minutes in a calendar month
                    minus the number of minutes of Downtime suffered from all
                    Downtime Periods in that month.
                  </p>
                  <p className="font-semibold">5. Monthly Uptime Percentage:</p>
                  <p>
                    Calculated by dividing Monthly Uptime by the total number of
                    minutes in a calendar month.
                  </p>
                  <p className="font-semibold">6. Scheduled Downtime:</p>
                  <p>
                    Denotes the informed unavailability of the Northstarz.Ai
                    Platform, communicated to Subscriber at least forty-eight
                    (48) hours in advance. Scheduled Downtime does not
                    constitute a Qualifying Fault.
                  </p>
                  <p className="font-semibold">
                    7. Novelio SLA Service Credit note:
                  </p>
                  <p>
                    Denotes Novelio Service Credit note raised for Novelio’s
                    failure to meet the monthly uptime commitment at no
                    additional cost.
                  </p>
                </div>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Novelio Support Scope
                </h2>
                <p>
                  1. Novelio will provide support for functionality delivered by
                  Novelio as part of the Northstarz.Ai Platform. However, for
                  all other functionality and/or issues or errors in the
                  Northstarz.Ai Platform resulting from Subscriber's information
                  systems, customizations, and/or third-party products or
                  services, Novelio may assist Subscriber and its third-party
                  providers in diagnosing and resolving issues or errors.
                  Subscriber acknowledges that these matters fall outside
                  Novelio's support obligations. Failure to meet obligations or
                  commitments under this SLA attributable to:
                </p>
                <ul className="list-disc pl-6">
                  <li>Subscriber's acts or omissions, and</li>
                  <li>Force majeure events shall be excused.</li>
                </ul>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Issue Submission and Reporting
                </h2>
                <p>
                  The registered contacts may submit cases to Novelio Support
                  through the Support Portal or Support phone number. Each case
                  will be assigned a unique case number, and Novelio will
                  respond and work towards resolution according to this SLA.
                </p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Severity Level Determination
                </h2>
                <p>
                  Subscriber shall reasonably self-diagnose each support issue
                  and recommend to Novelio an appropriate Severity Level
                  designation. Novelio shall validate Subscriber's Severity
                  Level designation or notify Subscriber of any change, with
                  justification. Severity Levels are defined as follows:
                </p>
                <div className="space-y-2">
                  <p className="font-semibold">Severity Level 1:</p>
                  <p>
                    The software, as a whole, is non-functional or not
                    accessible; unauthorized exposure of all or part of the
                    client's data; or loss or corruption of all or part of the
                    client's data.
                  </p>
                  <p className="font-semibold">Severity Level 2:</p>
                  <p>
                    Significant and/or ongoing interruption of an authorized
                    user’s use of a critical function of the software, with no
                    acceptable work-around available.
                  </p>
                  <p className="font-semibold">Severity Level 3:</p>
                  <p>
                    Minor and/or limited interruption of an authorized user’s
                    use of a non-critical function of the software; or problems
                    not included in Severity Levels 1 or 2.
                  </p>
                  <p className="font-semibold">Severity Level 4:</p>
                  <p>
                    General questions about the software; or configuration
                    changes previously agreed to be in scope by the client.
                  </p>
                </div>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Response and Resolution
                </h2>
                <table className="table-auto border-collapse border border-gray-300 w-full">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 p-2">
                        Severity Level
                      </th>
                      <th className="border border-gray-300 p-2">
                        Response (business hours)
                      </th>
                      <th className="border border-gray-300 p-2">
                        Problem Determination (business hours/business days)
                      </th>
                      <th className="border border-gray-300 p-2">
                        Restoration/Work-around (business days)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 p-2">1</td>
                      <td className="border border-gray-300 p-2">2 Hours</td>
                      <td className="border border-gray-300 p-2">4 Hours</td>
                      <td className="border border-gray-300 p-2">8 Hours</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">2</td>
                      <td className="border border-gray-300 p-2">2 Hours</td>
                      <td className="border border-gray-300 p-2">4 Hours</td>
                      <td className="border border-gray-300 p-2">3 Days</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">3</td>
                      <td className="border border-gray-300 p-2">2 Hours</td>
                      <td className="border border-gray-300 p-2">3 Days</td>
                      <td className="border border-gray-300 p-2">10 Days</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">4</td>
                      <td className="border border-gray-300 p-2">3 Days</td>
                      <td className="border border-gray-300 p-2">10 Days</td>
                      <td className="border border-gray-300 p-2">14 Days</td>
                    </tr>
                  </tbody>
                </table>
              </section>

              <h2 className="text-2xl font-semibold">Exclusions</h2>
              <p>
                The SLA does not apply to performance and availability issues
                caused by factors outside Novelio’s reasonable control; issues
                resulting from any actions or inactions of Subscriber; or issues
                resulting from Subscriber’s equipment and/or third-party
                equipment not within Novelio’s reasonable control.
              </p>
            </div>

            <div className="pt-6 space-y-6">
              <h1 className="text-3xl font-bold">
                Exhibit 3: Data Protection Policy
              </h1>
              <p className="text-lg">
                Northstarz.Ai (A product of Novelio Technologies Private
                Limited) is committed to adhering to the General Data Protection
                Regulation (GDPR). We maintain product features, corporate
                protocols, and legal documents to help our users and customers
                comply. The following are some of the frequently asked questions
                to help our customers, employees, and candidates. The detailed
                policy can be accessed{" "}
                <a href="#" className="text-blue-500">
                  here
                </a>
                .
              </p>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  What data do we collect?
                </h2>
                <p>
                  We collect what is being gathered through the Service consists
                  of any personal, employment, payroll, and location information
                  provided consciously and voluntarily by a Customer (Employer
                  or its authorised partners/employee), End User or the
                  Customer’s administrator or through use of our platform. The
                  employers, through our hiring platform, may also collect
                  information from a job-seeking candidate. In addition, the
                  employer might seek location information for time-keeping
                  purposes.
                </p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  What is our privacy policy?
                </h2>
                <p>
                  Our privacy policy can be accessed{" "}
                  <a href="#" className="text-blue-500">
                    here
                  </a>{" "}
                  and covers all aspects of GDPR adherence in much detail.
                </p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Who is responsible for employee data?
                </h2>
                <p>
                  Any Northstarz.Ai (A product of Novelio technologies Private
                  Limited) customer that uses our services to store their
                  employee or candidate information, owns the data of all
                  employees. The responsibility of updating and deleting all
                  employee data therefore lies with the customer. We provide our
                  customers with necessary support (customer support/ product
                  features) to carry out any such requests however and whenever
                  the customer wants to.
                </p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  For how long is the data stored?
                </h2>
                <p>
                  It depends on the contract with our customer. By default, we
                  store data until it's explicitly removed. But we provide
                  provisions to set up a periodic data removal process for our
                  clients on a contract-to-contract basis. However, we always
                  support data deletion through requests sent to{" "}
                  <a
                    href="mailto:support@northstarz.ai"
                    className="text-blue-500"
                  >
                    support@northstarz.ai
                  </a>{" "}
                  for all of our customers. We delete data at the
                  specified/requested time by our customers with an additional
                  grace period.
                </p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Who has access to the data?
                </h2>
                <ul className="list-disc pl-6 space-y-2">
                  <li>
                    Customer representatives who have access to our platform
                    services to maintain employee data.
                  </li>
                  <li>
                    Northstarz.Ai internal team only when a support request is
                    raised by the Customer and data access is necessary to
                    support such request.
                  </li>
                </ul>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Who can delete the employee information?
                </h2>
                <p>
                  The data retention is governed by the agreement between a
                  customer (employer or authorised partners) and the candidates.
                  The customer is thus responsible for any deletion of any
                  candidate's information.
                </p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Can the deleted data be reinstated?
                </h2>
                <p>
                  An exit action on an employee continues to retain all the
                  information of the employee in the system. The actual deletion
                  action is permanent and cannot be reinstated.
                </p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Can I delete/edit/view/access my personal information?
                </h2>
                <p>
                  Northstarz.Ai is a Service provider and your data provided by
                  the candidates, is owned by the customer who manages your
                  access. Please contact your employer (customer) directly to
                  request the deletion or updation of your data collected during
                  the interview process.
                </p>
              </section>
            </div>

            <p className="mt-6">Last Updated: January 12, 2024</p>
            <p>© 2023–2030 ALL RIGHTS RESERVED</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;
