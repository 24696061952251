import React from 'react'
import CaseStudiesImage from '../images/case_studeis.png'
import WhiteButton from './WhiteButton'
import CaseStudy1 from '../images/case_study (1).png'
import CaseStudy2 from '../images/case_study (2).png'
const NewCaseStudies = () => {
  return (
    <div>
        <div className='flex flex-col gap-4'>
            <p className="text-[#9B8AFB] text-center font-thicboi font-semibold text-xs md:text-sm">PROVEN RESULTS</p>
            <p className="text-white text-3xl md:text-5xl text-center  ">Case studies</p>
            <p className="text-[#B9C0D4] md:text-base text-xs text-center">Transforming Hiring Processes Across Industries</p>
        </div>
        <div className="mt-16 md:flex gap-4  tems-center space-y-5 md:space-y-0 justify-center  md:space-x-4 px-4">
            <div>
            <div className="shadow-xl ">
                <img src={CaseStudy1} className="w-full"></img>
                
            </div>
            <div className=" w-full  py-4 bottom-0 text-white">
                    <p className="text-2xl text-white  font-semibold opacity-80">How EPIC Investment Partners Cut Interview Time by 90% Using AI – Here’s What Happened!</p>
                    {/* <div className="inline-block mt-6">
                        <WhiteButton text="Learn More"/>
                    </div> */}
                </div>
            </div>
           
            <div>
            <div className=" ">
                <img src={CaseStudy2} className="w-full"></img>
                
            </div>
            <div className=" w-full  py-4 bottom-0 text-white">
                    <p className="text-2xl text-white  font-semibold opacity-80">Novatr Tripled Hiring Efficiency and Filled 4 Roles in Just 2 Weeks – Discover Their Secret!</p>
                    {/* <div className="inline-block mt-6">
                        <WhiteButton text="Learn More"/>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewCaseStudies