import React, { useEffect } from "react";
import logo from "../images/logo.png";
import WhiteButton from "../components/WhiteButton";
import TransparentButton from "../components/TransparentButton";
import background from "../images/Privacybackground.png";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-full">
      <div
        style={{
          backgroundImage: `url(${background})`,
        }}
        className="bg-[#0d121e] h-full bg-cover font-thicboi px-6 md:px-20 py-10"
      >
        <Header />
        <div className="flex flex-col px-6 md:py-20 items-center text-center gap-6">
          <div className="md:w-[50%]">
            <p className="text-white font-dmSerif italic text-7xl">
              Privacy Policy
            </p>
          </div>
          <div className="md:w-[70%] flex items-center justify-center">
            <p className="text-white text-center font-extralight font-sans">
              Welcome to Northstarz.Ai, a product of Novelio Technologies
              Private Limited ("Northstarz.Ai," "we," "our," or "us").
              Protecting your privacy is important to us, and this Privacy
              Policy explains how we collect, use, and disclose information from
              users of our website and related services (collectively, the
              "Services"). By using our Services, you agree to the practices
              described in this Privacy Policy.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#0d121e] px-6 md:px-40 py-20">
        <div className="text-white w-full font-thicboi font-extralight text-[18px] leading-7 flex flex-col gap-6 px-4 py-6">
          {/* Add each privacy policy section here */}
          <div>
            <h2 className="text-3xl font-bold">Northstarz</h2>
            <h3 className="text-3xl font-bold">
              Novelio Technologies Private Limited Privacy Policy
            </h3>
            <p>
              Welcome to Northstarz.Ai, a product of Novelio Technologies
              Private Limited ("Northstarz.Ai," "we," "our," or "us").
              Protecting your privacy is important to us, and this Privacy
              Policy explains how we collect, use, and disclose information from
              users of our website and related services (collectively, the
              "Services"). By using our Services, you agree to the practices
              described in this Privacy Policy.
            </p>
          </div>

          <div>
            <h2 className="text-3xl font-bold">Information We Collect</h2>
            <p>
              <strong>Information You Provide to Us</strong>
            </p>
            <ul className="list-disc pl-5">
              <li>
                Registration Information: Your name, work email address, contact
                phone number, and organization details.
              </li>
              <li>
                Communications: Information provided when contacting us
                directly, such as your name, email address, phone number, and
                message contents. By providing this information, you consent to
                receive newsletters, marketing campaigns, and be part of our
                mailing lists.
              </li>
              <li>
                Job Applications: Relevant details from your resume for job
                applications, such as identifiers, employment-related
                information, and more.
              </li>
              <li>
                Chat Windows: Information entered into chat windows, processed
                using third-party APIs for automation services.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-3xl font-bold">
              Other Information We Collect When You Use Our Services
            </h2>
            <ul className="list-disc pl-5">
              <li>
                <strong>Location Information:</strong> Inferred general location
                information and precise location information with your
                permission.
              </li>
              <li>
                <strong>Device Information:</strong> Details about the device
                and software you use to access our Services.
              </li>
              <li>
                <strong>Usage Information:</strong> Information about your
                interactions with our Services to improve user experience.
              </li>
              <li>
                <strong>
                  Information from Cookies and Similar Technologies:
                </strong>{" "}
                We use cookies and similar technologies to collect information
                about your online activities.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl font-bold">
              Information We Receive from Third Parties
            </h2>
            <ul className="list-disc pl-5">
              <li>
                <strong>Information from Third Parties:</strong> If you link our
                Services to third-party services, we may receive information
                about you as outlined in this Privacy Policy.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl font-bold">Talent Search</h2>
            <p>
              Northstarz.Ai processes and stores searchable resume data for the
              purpose of facilitating employers in inviting candidates to apply
              for job openings. Candidates have control over the visibility of
              their resumes, with options to configure privacy settings.
            </p>
          </div>

          <div>
            <h2 className="text-3xl font-bold">
              How We Use the Information We Collect
            </h2>
            <ul className="list-disc pl-5">
              <li>Managing the recruitment system and job applications.</li>
              <li>Assessing skills, qualifications, and interests.</li>
              <li>Communicating with you about job opportunities.</li>
              <li>
                Verifying information through reference and background checks.
              </li>
              <li>
                Sending information about the new hire and employee experience.
              </li>
              <li>Improving our recruitment system and activities.</li>
              <li>Detecting and preventing fraud or illegal activities.</li>
              <li>Complying with legal obligations.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl font-bold">
              Automated Decisions About You
            </h2>
            <p>
              We may use automated decision-making processes for recruitment
              purposes, subject to legal requirements and your consent.
            </p>
          </div>

          <div>
            <h2 className="text-3xl font-bold">
              How We Disclose the Information We Collect
            </h2>
            <ul className="list-disc pl-5">
              <li>Vendors and Service Providers.</li>
              <li>Users and Public Information.</li>
              <li>Social Networks and Other Online Services.</li>
              <li>Marketing Communications.</li>
              <li>Analytics Partners.</li>
              <li>As Required By Law and Similar Disclosures.</li>
              <li>Merger, Sale, or Other Asset Transfers.</li>
              <li>With Your Consent.</li>
            </ul>
            <h2 className="text-3xl font-bold mt-6">Your Choices</h2>
            <ul className="list-disc pl-5">
              <li>Adjusting Sharing Preferences.</li>
              <li>Managing Public Information.</li>
              <li>Controlling Location Information.</li>
              <li>Disabling Third-Party Integrations.</li>
              <li>Managing Marketing Communications.</li>
              <li>Do Not Track: We do not respond to Do Not Track signals.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl font-bold">Third Parties</h2>
            <p>
              Our Services may contain links to third-party websites. This
              Privacy Policy does not apply to third-party services, so please
              review their privacy policies.
            </p>

            <h2 className="text-3xl font-bold mt-6">Log Data</h2>
            <p>
              When you use our Service through a mobile device, certain
              information is automatically collected, including, but not limited
              to, the type of mobile device, unique device ID, IP address,
              mobile operating system, the type of mobile Internet browser, and
              other statistics ("Log Data").
            </p>
            <p>
              Furthermore, we may utilize third-party services like Google
              Analytics to collect, monitor, and analyze this information,
              enhancing the functionality of our Service. These third-party
              service providers have their own privacy policies detailing how
              they handle such information.
            </p>
            <p>
              Please refer to the section on Location Information below for
              details on the use of your location information and the available
              options.
            </p>

            <h2 className="text-3xl font-bold mt-6">Location Information</h2>
            <p>
              Depending on the permissions you've set on your device, we may use
              and store location information. This data helps us provide
              features, enhance, and customize our Service. You can enable or
              disable location services at any time through your mobile device
              settings.
            </p>
            <p>
              Enabling location services ensures accurate location information
              for nearby points of interest, directions, and more. It's
              important to note that other users will never have access to your
              specific location, even if you share a spot. In such cases, the
              information will only be presented as another point of interest on
              the map.
            </p>
          </div>

          {/* Repeat similar structure for all other sections */}
          <div>
            <h2 className="text-3xl font-bold">Cookies</h2>
            <p>
              Cookies are small data files, including an anonymous unique
              identifier, sent to your browser from a website and stored on your
              device. We use cookies to collect information to improve our
              services.
            </p>
            <p>
              You can configure your browser to reject all cookies or notify you
              when receiving a new cookie. Most browsers provide information on
              managing cookies. However, if you choose not to accept cookies,
              some features of our Service may be unavailable. We recommend
              keeping cookies turned on for optimal service use.
            </p>
          </div>

          <div>
            <h2 className="text-3xl font-bold">Children’s Privacy</h2>
            <p>
              Our Services are not intended for children under 13, and we do not
              knowingly collect personal information from them.
            </p>

            <h2 className="text-3xl font-bold mt-6">Service Providers</h2>
            <p>
              We may employ third-party companies and individuals to facilitate
              our Service, to provide the Service on our behalf, to perform
              Service-related services, and/or to assist us in analyzing how our
              Service is used.
            </p>
            <p>
              These third parties have access to your Personal Information only
              to perform specific tasks on our behalf and are obligated not to
              disclose or use your information for any other purpose.
            </p>

            <h2 className="text-3xl font-bold mt-6">Communications</h2>
            <p>
              We may use your Personal Information to contact you with
              newsletters, marketing or promotional materials, and other
              information that may be of interest to you. You may opt out of
              receiving any, or all, of these communications from us by
              following the unsubscribe link or instructions provided in any
              email we send or by contacting us.
            </p>

            <h2 className="text-3xl font-bold mt-6">Compliance With Laws</h2>
            <p>
              We will disclose your Personal Information where required to do so
              by law or subpoena or if we believe that such action is necessary
              to comply with the law and the reasonable requests of law
              enforcement or to protect the security or integrity of our
              Service.
            </p>

            <h2 className="text-3xl font-bold mt-6">Business Transaction</h2>
            <p>
              If Northstarz.Ai is involved in a merger, acquisition, or asset
              sale, your Personal Information may be transferred as a business
              asset. In such cases, we will provide notice before your Personal
              Information is transferred and/or becomes subject to a different
              Privacy Policy.
            </p>

            <h2 className="text-3xl font-bold mt-6">Security</h2>
            <p>
              The security of your Personal Information is important to us, and
              we strive to implement and maintain reasonable, commercially
              acceptable security procedures and practices appropriate to the
              nature of the information we store, in order to protect it from
              unauthorized access, destruction, use, modification, or
              disclosure.
            </p>
            <p>
              However, please be aware that no method of transmission over the
              internet, or method of electronic storage is 100% secure and we
              are unable to guarantee the absolute security of the Personal
              Information we have collected from you.
            </p>

            <h2 className="text-3xl font-bold mt-6">International Transfer</h2>
            <p>
              Your information, including Personal Information, may be
              transferred to - and maintained on - computers located outside of
              your state, province, country, or other governmental jurisdiction
              where the data protection laws may differ from those from your
              jurisdiction.
            </p>
            <p>
              If you are located outside India and choose to provide information
              to us, please note that we transfer the information, including
              Personal Information, to India and process it there.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>

            <h2 className="text-3xl font-bold mt-6">Update Your Information</h2>
            <p>
              You can update your account and profile information or close your
              account by sending us an email at{" "}
              <a
                href="mailto:support@northstarz.ai"
                className="text-blue-500 underline"
              >
                support@northstarz.ai
              </a>
              .
            </p>
          </div>

          <div>
            <h2 className="text-3xl font-bold">
              Changes to this Privacy Policy
            </h2>
            <p>
              We will post any updates to this Privacy Policy on this page. The
              revised version will take effect immediately upon posting. If
              there are significant changes, we will notify you through our
              Services or other communication channels.
            </p>

            <h2 className="text-3xl font-bold mt-6">Contact Information</h2>
            <p>
              For any questions, comments, or concerns regarding our data
              processing activities, feel free to email us at{" "}
              <a
                href="mailto:info@northstarz.ai"
                className="text-blue-500 underline"
              >
                info@northstarz.ai
              </a>
              .
            </p>

            <p className="mt-6">Last Updated: January 12, 2024</p>
            <p>© 2023–2030 ALL RIGHTS RESERVED</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
