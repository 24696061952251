import React from 'react'
import dots from '../images/dots.png'
import group from '../images/Users.png'
import chat from '../images/Chat.png'

const InfoSection = () => {
  return (
    <div data-aos="fade-up">
        <div className="md:grid gap-10 space-y-4 my-20 md:space-y-0 md:grid-cols-3">
          
              <div className="bg-transparent font-semibold  relative   pl-10  before:block before:bg-gradient-to-b before:from-[#00C6FB] before:to-[#005BEA] before:w-[2px] before:h-full before:content-'' before:absolute before:left-0 before:top-1/2 before:mr-4 before:transform before:-translate-y-1/2">
                <img src={dots} className="h-8"></img>
                <p className="textgradient text-2xl mt-4 font-thicboi font-semibold ">Identify skills like innovation, adaptability & collaboration displayed by the top 5% of applicants

                </p>
              </div>
              <div className="bg-transparent font-semibold  relative   pl-10  before:block before:bg-gradient-to-b before:from-[#00C6FB] before:to-[#005BEA] before:w-[2px] before:h-full before:content-'' before:absolute before:left-0 before:top-1/2  before:mr-4 before:transform before:-translate-y-1/2">
                <img src={group} className="h-8"></img>
                <p className="textgradient text-2xl mt-4 font-thicboi font-semibold">Trained on 30,000+ real human interview
responses
</p>
              </div>
              <div className="bg-transparent font-semibold  relative   pl-10  before:block before:bg-gradient-to-b before:from-[#00C6FB] before:to-[#005BEA] before:w-[2px] before:h-full before:content-'' before:absolute before:left-0 before:top-1/2  before:mr-4 before:transform before:-translate-y-1/2">
                <img src={chat} className="h-8"></img>
                <p className="textgradient text-2xl mt-4 font-thicboi font-semibold">In-house machine learning models that adapt dynamically to evolving talent profiles</p>
              </div>
              
          
        </div>
    </div>
  )
}

export default InfoSection