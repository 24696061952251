import React from "react";
import WhiteButton from "../components/WhiteButton";

const Process = () => {
  return (
    <div className="">
      <div className="">
        <div className=" -z-10">
          <div className="bg-[#1570EF] border-[2.41px] border-[#53B1FD] h-[365.81px] w-[365.81px] opacity-[.06] rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
          <div className="bg-[#1570EF] border-[2.41px] border-[#53B1FD]  h-[515.03px] w-[515.03px] opacity-[.06] rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
          <div className="bg-[#1570EF] border-[2.41px] border-[#53B1FD]  h-[678.68px] w-[678.68px] opacity-[.06] rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
          <div className="bg-[#1570EF] border-[2.41px] border-[#53B1FD]  h-[803.83px] w-[803.83px] opacity-[.06] rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
        </div>
      </div>
      {/* Floating balloons */}
      <div className="hidden md:block">
        <div className="">
          <div className="border border-white rounded-full absolute opacity-[0.5] translate-x-20 translate-y-20 -rotate-12">
            <p className="text-white px-4 py-2  text-center font-thicboi text backdrop-blur-xl backdrop-filter">
              Ai resume screening
            </p>
          </div>
        </div>
        <div className="border border-white rounded-full absolute opacity-[0.5] right-0 -translate-x-40 translate-y rotate-[8deg]">
          <p className="text-white px-4 py-2  text-center font-thicboi text backdrop-blur-xl backdrop-filter">
            Candidate Leaderboard
          </p>
        </div>
        <div className="border border-white rounded-full absolute opacity-[0.5] right-0 -translate-x-52 translate-y-24 -rotate-[20deg]">
          <p className="text-white px-4 py-2  text-center font-thicboi text backdrop-blur-xl backdrop-filter">
            Video interview screening
          </p>
        </div>
      </div>
      <div className="z-50 relative">
        <p
          data-aos="fade-up"
          className="text-[#9B8AFB] text-center font-thicboi font-semibold text-xs md:text-sm"
        >
          ACTION
        </p>
        <p
          data-aos="fade-up"
          className="text-white text-3xl md:text-5xl text-center  my-5"
        >
          How Northstarz.ai works
        </p>
        <p data-aos="fade-up" className="text-[#B9C0D4] text-center">
          Seamless, Intelligent Recruitment: From Job Listing to Candidate
          Selection
        </p>
        <div
          data-aos="fade-up"
          className="mt-16 md:flex  items-stretch justify-center space-y-4 md:space-y-0 md:space-x-5"
        >
          <div className="backdrop-filter customBorder2 backdrop-blur-xl  rounded-xl p-6 text-center md:w-1/3">
            <div className="py-2 px-3 bg-gradient-to-t inline-block mx-auto text-center from-[#1B5594] to-[#2E90FA] text-white rounded-full">
              STEP 1
            </div>
            <p className="text-center textgradient font-thicboi font-semibold mt-10  text-2xl">
              Human Eye Resume Evaluation
            </p>
            <p className="text-[#B9C0D4] text-center mt-4">
              AI scans and validates resume vis a vis JD. Scores resume with
              high accuracy. Understands the inputs of each resume contextually
              as intended by applicants
            </p>
          </div>
          <div className="backdrop-filter customBorder2 backdrop-blur-xl  rounded-xl p-6 text-center md:w-1/3">
            <div className="py-2 px-3 bg-gradient-to-t inline-block mx-auto text-center from-[#1B5594] to-[#2E90FA] text-white rounded-full">
              STEP 2
            </div>
            <p className="text-center textgradient font-thicboi font-semibold mt-10  text-2xl ">
              Star Powers
            </p>
            <p className="text-[#B9C0D4] text-center mt-4">
              Assess Innovation, adaptability and collaborative skills of each
              candidate to determine their ability to become future star
              performers
            </p>
          </div>
          <div className="backdrop-filter customBorder2 backdrop-blur-xl  rounded-xl p-6 text-center md:w-1/3">
            <div className="py-2 px-3 bg-gradient-to-t inline-block mx-auto text-center from-[#1B5594] to-[#2E90FA] text-white rounded-full">
              STEP 3
            </div>
            <p className="text-center textgradient font-thicboi font-semibold mt-10  text-2xl ">
              Technical Strength
            </p>
            <p className="text-[#B9C0D4] text-center mt-4">
              Evaluate functional fitment of each candidate as required by the
              job description for any skill. Technical pre-screening ensures
              smooth functional interviews and higher closures
            </p>
          </div>
        </div>
        <div className="text-center">
          <div data-aos="fade-up" className="mt-16 inline-block">
            <WhiteButton text="Request a demo" to={"/demo"}></WhiteButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
