import React from 'react'
import insights from '../images/insights.png'
const HumanEyeResumeEvaluation = () => {
  return (
    <div 
    style={{border:"none"}}
    className="relative flex   mt-20 justify-end w-full border-none  float-right">
        <div className=" h-[20rem] ">
            <div className="rounded-lg bg-gradient-to-b absolute w-2/3 -top-[60px] left-24 h-full from-[#1B55944D] to-[#2E90FA4D]"></div>
            <div className="rounded-lg bg-gradient-to-b absolute w-2/3 -top-12 left-20 h-full from-[#1B55944D] to-[#2E90FA4D]"></div>
            <div className="rounded-lg bg-gradient-to-b absolute w-2/3 -top-9 left-[60px] h-full from-[#1B55944D] to-[#2E90FA4D]"></div>
            <div className="rounded-lg bg-gradient-to-b absolute w-2/3 -top-6 left-10 h-full from-[#1B55944D] to-[#2E90FA4D]"></div>
            <div className="rounded-lg bg-gradient-to-b absolute -top-3 left-5 w-2/3 h-full from-[#1B55944D] to-[#2E90FA4D]"></div>
            <img className="rounded-lg bg-gradient-to-b absolute -top-3 left-5 w-2/3 h-full from-[#1B55944D] to-[#2E90FA4D]" src={insights}></img>
        </div>
    </div>
  )
}

export default HumanEyeResumeEvaluation