import React, { useState } from 'react';
import logo from '../images/logo.png';
import WhiteButton from '../components/WhiteButton';
import TransparentButton from '../components/TransparentButton';
import { Link } from 'react-router-dom';
import Menu from '../images/Menu 1.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className="flex py-6 items-center px-4 md:px-8  w-full justify-between">
        <div>
          <Link to="/">
            <img src={logo} className="h-10" alt="logo" />
          </Link>
        </div>

        <div className="flex md:hidden">
          <img src={Menu} alt="menu" onClick={toggleMenu} className="cursor-pointer" />
        </div>

        <div className="hidden md:flex items-center space-x-8 text-sm">
          <Link to="/about">
            {/* <p className="font-thicboi text-white cursor-pointer">ABOUT US</p> */}
          </Link>
          {/* <Link to="/pricing">
            <p className="font-thicboi text-white cursor-pointer">PRICING</p>
          </Link> */}
          {/* <p className="font-thicboi text-white cursor-pointer">RESOURCES</p> */}
        </div>

      
        <div className="hidden md:flex items-center space-x-4">
          <WhiteButton text={"Request a demo"} to={"/demo"} />
          <TransparentButton onclick={()=>window.location.href="https://northstarz.ai/app"} text={"Log In"} />
        </div>
      </nav>

      {menuOpen && (
        <div className="md:hidden flex flex-col items-center space-y-4 py-4 bg-transparent text-white">
          <Link to="/about" onClick={toggleMenu}>
            {/* <p className="font-thicboi cursor-pointer">ABOUT US</p> */}
          </Link>
          {/* <p className="font-thicboi cursor-pointer">RESOURCES</p> */}
          <WhiteButton text={"Request a demo"} to={"/demo"} />
          <TransparentButton text={"Log In"} />
        </div>
      )}
    </>
  );
};

export default Header;
