import React from 'react'
import woman_waving from '../images/headphonegirl.png'
import logo from '../images/logo.png'
import small_logo from '../images/small_logo.png'
import HeroSectionLowerLeftCard from './HeroSectionLowerLeftCard'
import HeroSectionTopMostCard from './HeroSectionTopMostCard'
import oval from '../images/Oval.png'
import oval2 from '../images/Oval 2.png'
import {ReactTyped} from "react-typed";
const AboutusInnerMostCard = () => {
  return (
    <div data-aos="fade-up" className="  border-[#FFFFFF33] border-2   bg-darkBlue relative ">
       <div  className='border-[#FFFFFF33] border-2 bg-darkBlue'>
            <div className="py-4 px-6">
                <img src={logo} className="h-5"></img>
            </div>
            <div className="bg-[#FFFFFF33] h-[0.05rem]"></div>
            <div className="p-4 flex space-x-4">
                <div className="w-3/5">
                    <img src={woman_waving} className="-z-60"></img>
                </div>
                <div className="customBorder rounded-lg p-4 w-2/5 flex flex-col justify-between">
                    <div className="">
                        <p className="text-center mt-4 text-sm md:block text-white md:text-xl  font-thicboi">Live Questions</p>
                        <p className="mt-4 text-center font-thicboi text-xs md:block hidden text-[#FFFFFF] opacity-20">Call Started | 02:35 pm</p>
                        <p className="mt-6 text-center font-thicboi hidden  text-xs text-[#FFFFFF] opacity-20">Apurva joined</p>
                        <div className="mt-4 flex items-end space-x-2 md:space-x-4">
                            <img src={small_logo} className="md:h-8 h-4"></img>
                            <div className="bg-[#30374F] p-4 rounded-lg   font-thicboi text-[0.4rem] md:text-xs text-white">
                                <p>Can you describe a time when you had to deal with a difficult customer? <ReactTyped 
                                    strings={["how did you handle the situation, and what was the outcome?"]}
                                    typeSpeed={100}
                                    
                                    backSpeed={20}
                                    // cursorChar=""
                                    showCursor={true}/>
                                </p>
                                {/* Speed slow of other section */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-[#FFFFFF] opacity-30 md:text-base text-xs font-thicboi rounded-full border px-4 mt-2 py-2">Ask any question</p>
                    </div>
                </div>
            </div>
        </div>
       

        <div className="absolute -right-44 -top-36 scale-75">
            <div className="">
                <HeroSectionTopMostCard/>
            </div>
        </div>
        <div className="absolute -left-48 -bottom-1 scale-75">
            <div className="">
                <HeroSectionLowerLeftCard></HeroSectionLowerLeftCard>
            </div>
        </div>
        <div className="absolute -left-44 -top-1  ">
            
            <img src={oval} className='w-[50px]'></img>
        
        </div>
        <div className="absolute  -left-44 top-1 -z-50 scale-75">
            
            <img src={oval2} className='w-1/2'></img>
        
        </div>
        
    </div>
  )
}


export default AboutusInnerMostCard