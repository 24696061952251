import React from 'react'
import graph from '../images/graph.png'

const HeroSectionLowerRightCard = () => {
  return (
    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="2000" className="relative customBorder2">
      <div
      className="font-thicboi customBorder2  innershadow rounded-lg bg-darkBlue p-[22.5px] "
      >
          <img src={graph} className="mx-auto  h-52"></img>
      </div>
    </div>
  )
}

export default HeroSectionLowerRightCard