import React, {useRef} from 'react'
import WhiteButton from './WhiteButton'
import sparkle from '../images/sparkle.png'
import suitcase from '../images/Suitcase.png'
import genJd from '../images/Card (1).png'
import genQuestions from '../images/Card (2).png'
import jobBoards from '../images/job_boards.png'
import mulitple from '../images/Imagery (2).png'
import vc from '../images/vc.png'
import viewprofile from '../images/viewprofile.png'
import {useIsVisible} from './useIsVisible'
import background from "../images/bg.png"


const Features = () => {

    const divRef1 = useRef(null)
    const divRef2 = useRef(null)
    const divRef3 = useRef(null)
    const divRef4 = useRef(null)
    const divRef5 = useRef(null)
    const divRef6 = useRef(null)

  return (
    <div>
        <div>
            <div className='flex flex-col gap-2'>
            <p className="text-[#9B8AFB] text-center font-thicboi font-semibold text-xs md:text-sm">BUILT FOR THE FUTURE</p>
            <p className="text-center font-semibold text-white font-thicboi text-2xl md:text-5xl ">The most future-proof AI 
            </p>
            <p className="text-center font-semibold text-white font-thicboi text-2xl md:text-5xl ">Assessment Portal</p>
            <p className="text-[#B9C0D4] md:text-base text-center">Revolutionize Your Recruitment with AI-Driven Solutions</p>
            </div>
            
            <div className="md:flex relative md:space-x-10 items-start w-full justify-start mt-[64px]">
                <div className=" hidden md:flex scale-[80%] sticky top-0 w-1/3  flex-col gap-4">
                <div>
                <button onClick ={()=>{divRef1?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}} className="py-[26px] px-4 text-left flex text-lg items-center space-x-5">
                        <p className="text-white bg-[#FFFFFF1A] px-3 py-1 rounded-full">1</p>
                        <p className={useIsVisible(divRef1, true)?"text-white":"text-white opacity-45"}>High-Speed AI Resume Assessment</p>
                    </button>
                    <button onClick ={()=>{divRef2?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}} className="py-[26px] text-left px-4 flex text-lg items-center space-x-5">
                        <p className="text-white bg-[#FFFFFF1A] px-3 py-1 rounded-full">2</p>
                        <p className={useIsVisible(divRef2, true)?"text-white":"text-white opacity-45"}>Privacy-Compliant and Data Protection Ready</p>
                    </button>
                    <button onClick ={()=>{divRef3?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}} className="py-[26px] text-left px-4 flex text-lg items-center space-x-5">
                        <p className="text-white bg-[#FFFFFF1A] px-3 py-1 rounded-full">3</p>
                        <p className={useIsVisible(divRef3, true)?"text-white":"text-white opacity-45"}>Identify Future Star Performers</p>
                    </button>
                    <button onClick ={()=>{divRef4?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}} className="py-[26px] text-left px-4 flex text-lg items-center space-x-5">
                        <p className="text-white bg-[#FFFFFF1A] px-3 py-1 rounded-full">4</p>
                        <p className={useIsVisible(divRef4, true)?"text-white":"text-white opacity-45"}>Evaluate Techno-Functional Skills Across Key Functions</p>
                    </button>
                    <button onClick ={()=>{divRef5?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}} className="py-[26px] text-left px-4 flex text-lg items-center space-x-5">
                        <p className="text-white bg-[#FFFFFF1A] px-3 py-1 rounded-full">5</p>
                        <p className={useIsVisible(divRef5, true)?"text-white":"text-white opacity-45"}>Real-Time Dashboard with Key Insights for Managers</p>
                    </button>
                    <button onClick ={()=>{divRef6?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}} className="py-[26px] text-left px-4 flex text-lg items-center space-x-5">
                        <p className="text-white bg-[#FFFFFF1A] px-3 py-1 rounded-full">6</p>
                        <p className={useIsVisible(divRef6, true)?"text-white":"text-white opacity-45"}>Hire Top Candidates While Cutting Costs by 3X</p>
                    </button>
                </div>
                    
                    <div  style={{ backgroundImage: `url(${background})` }} className="bg-local bg-cover px-4 py-6">
                        <p className="text-white text-xl">Want to know more?</p>
                        <p className="text-white text-sm font-extralight">Experience a detailed demo with our executive team</p>
                        <div className="mt-[40px] inline-block">
                            <WhiteButton text="Request a demo" to = {"/demo"}></WhiteButton>
                        </div>
                    </div>
                </div>
                {/* 2nd section starts here */}
                <div className="md:w-2/3">
                    <div ref={divRef1} className="border-[#30374F] border rounded-lg flex my-6 w-full  items-center">
                        <div  className="ml-4 w-1/2">
                            <img src={sparkle} className="h-8"></img>
                            <p className="textgradient text-xl md:text-3xl mt-4 font-semibold">Instantly evaluates hundreds of resumes, extracting skills, job alignment, and assigning assessment scores</p>
                        </div>
                        <div className="mt-[71px] w-1/2 flex justify-end ">
                            <img src={mulitple} className="h-72 md:h-96 mr-0"></img>
                            {/* <div className="flex items-center space-x-2">
                                <img src={suitcase} className="h-[60px] bg-[#FFFFFF1A] px-4 py-4 rounded-full"></img>
                                <div clas>

                                <p className="text-white text-xl">Sales Representative</p>
                                <p className="text-[#FFFFFF] text-sm opacity-50">Dept: Sales</p>
                                <p className="text-white text-sm opacity-50">YOE: 3 years</p>
                                </div>
                            </div>
                            <p className="text-white my-5 text-xl">Job Description</p>
                            <div className="mt-[24px] bg-[#7D89B01A] text-white rounded-xl">

                            </div> */}
                        </div>
                    </div>

                    <div ref={divRef2} className="border-[#30374F] border rounded-lg flex my-6 w-full  items-center">
                        <div className="ml-4 w-1/2">
                            <img src={sparkle} className="h-8"></img>
                            <p className="textgradient text-xl md:text-3xl mt-4 font-semibold">Compliant with local and international privacy policy and data protection guidance</p>
                        </div>
                        <div className="mt-[71px] w-1/2 flex justify-end ">
                            <img src={genQuestions} className="h-60 md:h-96 mr-0"></img>
                            
                        </div>
                    </div>

                    <div ref={divRef3} className="border-[#30374F] border py-3 rounded-lg flex my-6 w-full  items-center">
                        <div className="ml-4 w-1/2">
                            <img src={sparkle} className="h-8"></img>
                            <p className="textgradient text-xl md:text-3xl mt-4 font-semibold">Assess critical skills like critical thinking, teamwork, and communication to confidently identify and hire future star performers</p>
                        </div>
                        <div className="mt-[71px] w-1/2 flex justify-end ">
                            <img src={genJd} className="h-60  md:h-96 ml-4"></img>
                            
                        </div>
                    </div>

                    <div ref={divRef4} className="border-[#30374F] border rounded-lg py-16 flex my-6 w-full  items-center">
                        <div className="ml-4 w-1/2">
                            <img src={sparkle} className="h-8"></img>
                            <p className="textgradient text-xl md:text-3xl mt-4 font-semibold">⁠Assess techno-functional skills across Finance, Operations, Sales, Development, Support, and Analytics.</p>
                        </div>
                        <div className="w-1/2 flex justify-end ">
                            <img src={vc} className="rounded-lg my-5"></img>
                            
                        </div>
                    </div>

                    <div ref={divRef5} className="border-[#30374F] border py-16 rounded-lg flex my-6 w-full  items-center">
                        <div className="ml-4 w-1/2">
                            <img src={sparkle} className="h-8"></img>
                            <p className="textgradient text-xl md:text-3xl mt-4 font-semibold">Live user dashboard with top-of-the-finger information on crucial data</p>
                        </div>
                        <div className="w-1/2 flex justify-end ">
                            <img src={jobBoards} className="rounded-lg my-5"></img>
                            
                        </div>
                    </div>

                    <div ref={divRef6} className="border-[#30374F] border rounded-lg py-20 flex my-6 w-full  items-center">
                        <div className="ml-4 w-1/2">
                            <img src={sparkle} className="h-6 md:h-8"></img>
                            <p className="textgradient text-xl md:text-3xl mt-4 font-semibold">Reduce Costs by 3X by increasing recruiter output with AI assistance and automation</p>
                        </div>
                        <div className="w-1/2 flex justify-end ">
                            <img src={viewprofile} className=" my-5"></img>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Features