import React from 'react'
import memoji from '../images/memoji.png'
const HeroSectionTopMostCard = (props) => {
    const {className} = props
  return (
    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="2000" className={`${className}  customBorder2 md:scale-100  font-thicboi backdrop-filter backdrop-blur-xl aspect-auto  p-6 `}>
        <div className="flex items-center space-x-3">
            <img src={memoji} className="h-12 md:h-20 bg-[#30374F] rounded-full"></img>
            <div className="">
                <p className="text-white md:text-base text-sm">Apurva Jha</p>
                <p className="md:text-sm text-white opacity-20 text-xs">Business Development <br></br> Associate</p>
                <p className="text-sm text-white opacity-20">3 years</p>
            </div>
        </div>
        <p className="text-white my-4 text-sm">INSIGHT</p>
        <p className="text-sm text-white opacity-20">Apurva shows strong leadership traits</p>
        <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
                <p className="text-[#FEEE95] text-xl">85%</p>
                <p className="text-sm text-white opacity-20">Resume score</p>
            </div>
            <div>
                <p className="text-[#12B76A] text-xl">97%</p>
                <p className="text-sm text-white opacity-20">Employability score</p>
            </div>
            <div>
                <p className="text-[#FEEE95] text-xl">82%</p>
                <p className="text-sm text-white opacity-20">Technical score</p>
            </div>
            <div>
                <p className="text-[#12B76A] text-xl">97%</p>
                <p className="text-sm text-white opacity-20">Video Confidence <br></br> score</p>
            </div>
            
        </div>
    </div>
  )
}

export default HeroSectionTopMostCard