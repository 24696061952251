import React from 'react'
import blackdots from '../images/black_dots.png'
import blacktimer from '../images/blackTimer.png'
import blackpie from '../images/black_pie.png'
import blackslider from '../images/black_slider.png';
import blackchart from '../images/black_chart.png'
import blackgroup from '../images/black_users.png'
const Advantages = () => {
  return (
    <div className="font-thicboi text-center">
        <p data-aos="fade-up" className="text-[#9B8AFB]  font-thicboi font-semibold text-xs md:text-sm">ADVANTAGES</p>
        <p data-aos="fade-up" className="text-white text-3xl md:text-5xl  my-5">Why choose Northstarz.Ai?</p>
        <p data-aos="fade-up" className="text-[#B9C0D4] md:text-base text-xs">Unlock Efficiency and Excellence in Hiring with Our AI-Powered Platform</p>
        <div data-aos="fade-up" className="grid md:grid-cols-3 grid-cols-2 mt-[64px]  ">
            <div className="md:p-10 p-4 mx-auto text-center border-r border-b border-[#30374F66]">
                <img src={blackdots} className="h-9 mx-auto mb-7"></img>
                <p className="text-white text-xl md:text-2xl">Screen candidates faster, 
efficiently
</p>
                <p className="text-[#B9C0D4] md:text-base text-sm mt-2">Accelerate your hiring process with our AI-driven platform that screens candidates quickly and efficiently.</p>
            </div>
            <div className="md:p-10 p-4 mx-auto text-center md:border-r border-b border-[#30374F66]">
                <img src={blackgroup} className="h-9 mx-auto mb-7"></img>
                <p className="text-white text-xl md:text-2xl">Fully autonomous Video 
screening
</p>
                <p className="text-[#B9C0D4] md:text-base text-sm mt-2">Our AI runs initial screening interviews on our secure video platform with zero human intervention. Available 24/7 to take interviews based on the candidate’s availability
                </p>
            </div>
            <div className="md:p-10 p-4 mx-auto text-center border-b border-[#30374F66]">
                <img src={blacktimer} className="h-9 mx-auto mb-7"></img>
                <p className="text-white text-xl md:text-2xl">Increase output per recruiter 
by 5X
</p>

                <p className="text-[#B9C0D4] md:text-base text-sm mt-2">Eliminate 52% of your recruiter’s time spent on screening resumes, making call outs and conducting initial telephonic round.
                </p>
            </div>
            <div className="md:p-10 p-4 mx-auto text-center border-l md:border-l-0 md:border-r  border-[#30374F66]">
                <img src={blackdots} className="h-8 mx-auto mb-7"></img>
                <p className="text-white text-xl md:text-2xl">Integrated WhatsApp communication</p>
                <p className="text-[#B9C0D4] md:text-base text-sm mt-2">Our in-built WhatsApp integration allows candidates to stay updated about their application status, upcoming interviews and engaged candidates to keep them warm (*upcoming conversational AI based engagement)                </p>
            </div>
            <div className="md:p-10 p-4 mx-auto text-center border-r  border-[#30374F66]">
                <img src={blackslider} className="h-9 mx-auto mb-7"></img>
                <p className="text-white text-xl md:text-2xl flex flex-col"><span>Reduce Hiring </span>
                    
                <span>Failure</span>
</p>
                <p className="text-[#B9C0D4] md:text-base text-sm mt-2">Reduce chances of hiring failure by evaluating candidates on critical soft skills like communication and team work which can cost companies up to 200% of the candidates CTC
                </p>
            </div>
            <div className="md:p-10 p-4 mx-auto text-center border-t md:border-t-0  border-[#30374F66]">
                <img src={blackchart} className="h-9 mx-auto mb-7"></img>
                <p className="text-white text-xl md:text-2xl flex flex-col"><span>Data-Driven  </span>
                    
                <span>Insights</span>

</p>
                <p className="text-[#B9C0D4] md:text-base text-sm mt-2">Utilize advanced analytics to gain insights into recruitment metrics. Our AI delivers actionable reports on candidate performance and interview trends, helping you refine your hiring strategy and improve decision-making.

                </p>
            </div>
        </div>
    </div>
  )
}

export default Advantages