import React from 'react'
import table1 from '../images/table1.png'
import table2 from '../images/table2.png'

const HeroSectionLowerLeftCard = () => {
  return (
    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="2000" className="relative md:scale-100  ">
        <div
        className="font-thicboi pt-2 customBorder2 innershadow  rounded-lg bg-darkBlue  "
        >
          <div  className="overflow-clip ">
          <p className="text-white text-xl opacity-45 px-6 py-3">Manage candidates</p>
          <img src={table2} className="h-52 pl-6 "></img>
          <img  src={table1} className="h-44 pr-6 -z-20  -mt-24"></img>
          </div>
        </div>
    </div>
  )
}

export default HeroSectionLowerLeftCard