import React from 'react'
import WhiteButton from './WhiteButton'
import jobBoards from '../images/job_boards.png'

const Integrate = () => {
  return (
    <div>
       <div data-aos="fade-up" className="flex items-center justify-between w-full">
        <div>
        <p className="text-[#9B8AFB]  font-thicboi font-semibold text-sm">INTEGRATIONS</p>
            <p className=" font-semibold text-white font-thicboi text-4xl py-5 pb-10">Integrate your legacy ATS</p>
            <p className="text-[#B9C0D4] ">We seamlessly connects with your favorite ATS, so you don't have to change your workflows.</p>
            {/* <div className="inline-block mt-16">
                <WhiteButton text="Explore Integrations"/>
            </div> */}
        </div>
        <img src={jobBoards} className="h-96"></img>
        </div> 
    </div>
  )
}

export default Integrate