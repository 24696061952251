import React, {useState} from 'react'
import avatar from '../images/Avatar.png'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const data=[
    {
      "content":"“I was a bit nervous about an AI interview, but the process was seamless. The platform was easy to navigate, and I liked being able to take the interview at my convenience. Overall, a great experience!”",
      "name":"Yogita Rawat",
    },
    {
      "content":"“I appreciated the clear instructions and the structured process. It saved me time compared to traditional interviews, and I liked that the questions were well-tailored to my skillset”",
      "name":"Durgesh Sharma",
      "designation":"CEO, Novatr"
    },
    {
      "content":"“I was initially skeptical about AI interviewing, but it felt very professional and well thought out. It’s amazing how close to a real interview it felt—great for first impressions!”",
      "name":"Rohan",
      "designation":"CEO, Novatr"
    },
    {
      "content":"“The process was quick and straightforward. It’s awesome to be able to complete the interview on my schedule. The questions felt relevant, and the platform was easy to use!”",
      "name":"Deepanshu Rao",
      "designation":"CEO, Novatr"
    },
    {
      "content":"“After completing the interview, I felt it truly assessed my skills. The platform was smooth, and I could see why companies are turning to this technology for efficiency! ”",
      "name":"Yogesh Nagpal",
      "designation":"CEO, Novatr"
    },
  ]
  return (
    <div>
      <div className='flex flex-col gap-2'>
      <p data-aos="fade-up" className="text-[#9B8AFB] text-center font-thicboi font-semibold text-sm">CANDIDATE STORIES</p>
        <p className="text-center font-semibold text-white font-thicboi mx-4 mb-5 text-4xl"> Experiences That Speak for Themselves</p>
        <p className="text-[#B9C0D4] text-center font-thicboi mx-4 text-base">Transforming interviews into seamless experiences</p>
      </div>
        

        <Swiper
        slidesPerView={2}
        spaceBetween={10}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        // onSwiper={(swiper) =>{console.log(swiper)}}
                          // Enables infinite scrolling
        autoplay={{
          delay: 3000,                   // Minimal delay between slides
          disableOnInteraction: true,
        }}
        freeMode={true}
        onSlideChange={(swiper)=>{
          setActiveIndex(swiper.activeIndex)
        }}
        modules={[Pagination, Autoplay]}
        className="w-full mt-20 px-5"
      >
        {
          data.map((d,i)=>{
            return(
            <SwiperSlide
            style={activeIndex==i?{ transform:"scale(1.2)"}:{transform:"scale(0.8)"}}
            
            className={activeIndex == i?" transition-all  backdrop-blur  bg-black bg-opacity-30 px-6 rounded-xl w-1/2 backdrop-filter ":" z-20 transition-all  pt-[100px] pb-[86px] backdrop-blur-lg bg-black opacity-20 px-6 rounded-lg w-1/2 backdrop-filter"}
            >
                <div className={activeIndex == i?'pt-[100px] pb-[80px] rounded-xl':""}>
                    <p className="font-dmSerif textgradientgolden text-center text-xl md:text-3xl italic">{d.content}</p>
                    <div className="flex space-x-2 font-thicboi items-center justify-center mt-20">
                        {/* <img src={avatar} className="h-12"></img> */}
                        <div>
                            <p className="text-white">{d.name}</p>
                        </div>
                    </div>
                </div>
            
            </SwiperSlide>
            );
          })
        }
        {/* <SwiperSlide
        className="pt-[100px] pb-[86px] backdrop-blur-lg bg-black bg-opacity-50 px-6 rounded-lg w-1/2 backdrop-filter">
        <div>
                <p className="font-dmSerif textgradientgolden text-3xl italic">“Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.”</p>
                <div className="flex space-x-2 font-thicboi items-center justify-center mt-20">
                    <img src={avatar} className="h-12"></img>
                    <div>
                        <p className="text-white">Sia John</p>
                        <p className="text-white">CEO, Novatr</p>
                    </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide
        className="pt-[100px] pb-[86px] backdrop-blur-lg bg-black bg-opacity-50 px-6 rounded-lg w-1/2 backdrop-filter">
        <div>
                <p className="font-dmSerif textgradientgolden text-3xl italic">“Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.”</p>
                <div className="flex space-x-2 font-thicboi items-center justify-center mt-20">
                    <img src={avatar} className="h-12"></img>
                    <div>
                        <p className="text-white">Sia John</p>
                        <p className="text-white">CEO, Novatr</p>
                    </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide
        className="pt-[100px] pb-[86px] backdrop-blur-lg bg-black bg-opacity-50 px-6 rounded-lg w-1/2 backdrop-filter">
        <div>
                <p className="font-dmSerif textgradientgolden text-3xl italic">“Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.”</p>
                <div className="flex space-x-2 font-thicboi items-center justify-center mt-20">
                    <img src={avatar} className="h-12"></img>
                    <div>
                        <p className="text-white">Sia John</p>
                        <p className="text-white">CEO, Novatr</p>
                    </div>
                </div>
            </div>
        </SwiperSlide> */}
        
      </Swiper>
    </div>
  )
}

export default Carousel