import React from 'react'
import apply from '../images/Imagery (3).png'
import vc from '../images/vc2.png'
import phones from '../images/phones.png'
import _5x from '../images/5x.png';
import progess from '../images/progress.png';
const Love = () => {
  return (
    <div>
        <div className='flex flex-col gap-4'>
        <p data-aos="fade-up" className="text-[#9B8AFB] text-center font-thicboi font-semibold text-xs md:text-sm">THEIR INTERVIEW BUDDY</p>
        <p data-aos="fade-up" className="text-center font-semibold text-white font-thicboi text-2xl md:text-4xl">Why candidates love Northstarz.Ai</p>
        <p data-aos="fade-up" className="text-[#B9C0D4] text-sm text-center">Revolutionize Your Recruitment with AI-Driven Solutions</p>
        </div>
         
        <div data-aos="fade-up" className="md:flex mt-[64px] w-full md:space-y-0 space-y-3 md:space-x-4">
            <div  className="relative md:w-1/3">
                <div className="rounded-lg customBorder2">
                    <img src={apply} className="w-full"></img>
                    <div className="p-[40px] ">
                        <p className="textgradient font-bold text-3xl">Easy to Use</p>
                        <p className="mt-4 text-[#B9C0D4] ">84% of candidates have rated us four stars and above: Candidates trust us. Most of our users complete the end-to-end interview journey in less than 20 minutes</p>
                    </div>
                </div>
            </div>
            <div className=" md:w-1/3 relative">
                <div className="customBorder2">
                    <img src={vc} className="w-full  object-fill"></img>
                    <div className="p-[40px] ">
                        <p className="textgradient font-bold text-3xl">Candidate Support</p>
                        <p className="mt-4 text-[#B9C0D4] ">Candidate Support: Applicants offered tutorials, company information and support instruction over Chat and mail. Candidates can reach out to our conversation AI Chat Support (*upcoming feature) to find answers to their problems instantly</p>
                    </div>
                </div>
            </div>
            <div className="md:w-1/3 relative">
                <div className="customBorder2">
                    <img src={phones} className="w-full"></img>
                    <div className="p-[40px] ">
                        <p className="textgradient font-bold text-3xl">Interview anytime, on any device</p>
                        <p className="mt-4 text-[#B9C0D4] ">Interview anytime, on any device: Our AI is available to engage with candidates any time of the day, giving applicants the flexibility to interview at their own time on any device</p>
                    </div>
                </div>
            </div>
        </div>
        <div data-aos="fade-up" className="md:flex mt-4 w-full md:space-y-0 space-y-4 md:space-x-4">
        <div className="md:w-1/2 relative">
                <div className="customBorder2">
                    <img src={_5x} className="w-full"></img>
                    <div className="p-[40px] ">
                        <p className="textgradient font-bold text-3xl">Faster Interview Cycles</p>
                        <p className="mt-4 text-[#B9C0D4] ">AI tools streamline the interview process, making it faster and more efficient for candidates and 
                        employers</p>
                    </div>
                </div>
            </div>
            <div className="md:w-1/2 relative">
                <div className="customBorder2">
                    <img src={progess} className="w-full"></img>
                    <div className="p-[40px] ">
                        <p className="textgradient font-bold text-3xl">Greater Accessibility</p>
                        <p className="mt-4 text-[#B9C0D4] ">AI interview tools enhance accessibility for a wider range of candidates, including those with disabilities or challenges that complicate traditional interviews
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Love