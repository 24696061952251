import React, {useRef} from 'react'
import epic_logo from '../images/epic.png'
import vcs_logo from '../images/VCS.png'
import change_logo from '../images/change.png'
import bridge_logo from '../images/bridge.png'
import board_logo from '../images/board.png'
import flip_logo from '../images/flip.png'
import mahindra_logo from '../images/mahindra.png'
import sigma_logo from '../images/sigma.png'
import Media_logo from '../images/MediaAnt.png'
import eno_logo from "../images/eno.png"
import good_logo from "../images/good.png"
import byd_logo from "../images/byd.png"
import talreso_logo from "../images/talreso.png"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css"; 
import "swiper/css/autoplay"; 
import CountUp from 'react-countup';
import { useIsVisible } from './useIsVisible'



const Numbers = () => {
  const refCounter1 = useRef()
  const refCounter2 = useRef()
  const refCounter3 = useRef()
  return (
    <div>
        <div data-aos="fade-up" className="md:flex space-y-5 md:space-y-0  items-center justify-evenly text-white w-full">
            <div className="text-center">
                <p ref={refCounter1} className="text-5xl font-semibold">{
                  useIsVisible(refCounter1, false)?
                  <CountUp start={0} end={18574} duration={4}></CountUp>
                    :
                    null
                  } +</p>
                <p className="mt-4 font-extralight">Applicants who have applied through us</p>
            </div>
            <div className="bg-[#B9C0D4] hidden md:block px-[0.06rem] h-[3rem]"></div>
            <div className="text-center">
            <p ref={refCounter2} className="text-5xl font-semibold">{
                  useIsVisible(refCounter2, false)?
                  <CountUp start={0} end={81} duration={4}></CountUp>
                    :
                    null
                  } %</p>
                <p className="mt-4 font-extralight">Users have rated us over 4 Stars</p>
            </div>
            <div className="bg-[#B9C0D4] hidden md:block px-[0.04rem] h-[3rem]"></div>
            <div className="text-center">
            <p ref={refCounter3} className="text-5xl font-semibold">{
                  useIsVisible(refCounter3, false)?
                  <CountUp start={0} end={5000} duration={4}></CountUp>
                    :
                    null
                  } +</p>
                <p className="mt-4 font-extralight">Interviews completed on the platform</p>
            </div>
        </div>
        <div data-aos="fade-up" className="mt-[80px] flex flex-col gap-4">
      <p className="text-[#7D89B0] text-center font-thicboi font-semibold text-xs md:text-sm">
      COMPANIES TRUSTING US ALREADY
      </p>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={4}
        centeredSlides          // Automatically adjusts to fit available space
        spaceBetween={10}  // Default spacing
        breakpoints={{
          768: {           // Screens at 768px and below
            spaceBetween: 100,
          },}}         // Spacing between slides
        loop={true}                   // Enables infinite scrolling
        autoplay={{
          delay: 1,                   // Minimal delay between slides
          disableOnInteraction: false,
        }}
        speed={2000}                  // Controls the speed of the slide transition
        freeMode={true}               // Continuous sliding without snap
        className="flex  mt-[24px] w-full"
      >
        <SwiperSlide><img src={epic_logo} className="md:h-14  h-6" alt="Epic Logo" /></SwiperSlide>
        <SwiperSlide><img src={eno_logo} className="md:h-14 md:w-full h-6" alt="ENO Logo" /></SwiperSlide>
        <SwiperSlide><img src={Media_logo} className="md:h-14 h-6" alt="Media Logo" /></SwiperSlide>
        <SwiperSlide><img src={mahindra_logo} className="md:h-14 h-6" alt="Mahindra Logo" /></SwiperSlide>
        <SwiperSlide><img src={vcs_logo} className="md:h-14 h-6" alt="VCS Logo" /></SwiperSlide>
        <SwiperSlide><img src={good_logo} className="md:h-14 h-6" alt="Good Logo" /></SwiperSlide>
        <SwiperSlide><img src={talreso_logo} className="md:h-14 h-6" alt="Good Logo" /></SwiperSlide>
        <SwiperSlide><img src={byd_logo} className="md:h-14 h-6" alt="BYD Logo" /></SwiperSlide>
      </Swiper>
    </div>
    <div data-aos="fade-up" className="mt-[80px] flex flex-col gap-4">
        <p className="text-[#7D89B0] text-center font-thicboi font-semibold text-xs md:text-sm">PARTNERSHIPS WITH ESTABLISHED HR CONSULTING AND LEADING SOURCING PARTNERS</p>
            <div className="flex justify-center w-full space-x-2 md:space-x-12 items-center mt-[24px]">
                <img src={change_logo} className="md:h-10 h-4"></img>
                <img src={bridge_logo} className="md:h-10 h-4"></img>
                <img src={board_logo} className="md:h-10 h-4"></img>
                <img src={flip_logo} className="md:h-10 h-4"></img>
                <img src={talreso_logo} className="md:h-10 h-6" alt="Good Logo" />
               
            </div>
        </div>
    </div>
  )
}

export default Numbers