import React, { useState } from "react";
import Faq from "../images/Label contaienr.png";
import plus from "../images/Icon.png";
import WhiteButton from "./WhiteButton";

const FAQ = ({ fade }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedIndex2, setExpandedIndex2] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const toggleExpand2 = (index) => {
    setExpandedIndex2(expandedIndex2 === index ? null : index);
  };

  const faqData = [
    {
      question: "How does Northstarz.ai ensure a fair interview process?",
      answer:
        "Northstarz.ai’s cheat detection system assesses if a candidate is using an AI tool or the Internet to answer the questions during the interview and provides the percentage use of such external help in the candidate report, ensuring that candidates participate fairly and that the process remains free of manipulation or bias.",
    },
    {
      question: " How does Northstarz.ai improve hiring outcomes?",
      answer:
        "Northstarz.ai provides deep insights into candidates’ skills, personality traits, and overall fit for the role, enabling more informed and predictive hiring decisions, while reducing time spent on unsuitable candidates.",
    },
    {
      question:
        "Can Northstarz.ai’s AI handle different job roles and industries?",
      answer:
        "Yes, Northstarz.ai is highly customizable. You can tailor the interview process to different job roles or industries, making it suitable for various positions across sectors like tech, banking, insurance, staffing, retail, healthcare, and more.",
    },
    {
      question: " How does Northstarz.ai save time for recruiters?",
      answer:
        "Northstarz.ai automates the initial stages of the recruitment process, allowing recruiters to focus on the best-fit candidates. The AI interviews run round-the-clock, delivering instant feedback that saves valuable time during hiring.",
    },
  ];

  const faq2Data = [
    {
      question: "How does Northstarz.ai help with candidate engagement?",
      answer:
        "Northstarz.ai creates a personalized, consistent experience for each candidate, boosting engagement and allowing them to showcase their skills effectively. This ensures a positive candidate experience throughout the recruitment process. Additionally, the upcoming conversational AI support agent will work closely with the candidate to answer any queries the candidate may have.",
    },
    {
      question:
        "What types of data does Northstarz.ai provide after an interview?",
      answer:
        "Northstarz.ai generates detailed reports with predictive insights based on the interview, instantly uploaded to your dashboard. These insights help recruiters make more data-driven, confident hiring decisions. The insights provided are around the candidates resume fitment, employability skills, technical skills, current and expected salaries and notice period.",
    },
    {
      question: "Can I integrate Northstarz.ai with my existing ATS?",
      answer:
        "Yes, Northstarz.ai integrates seamlessly with most ATS platforms, allowing you to enrich your candidate data with actionable insights from AI-driven interviews, optimizing your recruitment workflow.",
    },
    {
      question: "What AI tool does Northstarz.ai use to evaluate interviews?",
      answer:
        "Northstarz.ai uses proprietary in-house algorithms to conduct its AI interviews.",
    },
  ];

  return (
    <div className="bg-[#0E101B]" {...(fade && { "data-aos": "fade-up" })}>
      <div className="text-white font-thicboi font-extralight leading-9 px-6 p-20 md:p-40">
        <div className="flex flex-col justify-center w-full gap-10 md:gap-16">
          <div className="flex flex-col items-center gap-2">
            <img src={Faq} alt="FAQ" />
            <span className="font-thicboi font-bold text-3xl md:text-5xl text-white">
              Most asked questions
            </span>
          </div>

          <div className="flex gap-12 flex-col md:flex-row w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 w-full">
              <div className="flex flex-col gap-4">
                {faqData.map((faq, index) => (
                  <div
                    key={index}
                    className="border-b-[1px] border-[#7D89B0] cursor-pointer"
                    onClick={() => toggleExpand(index)}
                  >
                    <div className="flex gap-4 p-6 justify-between items-center">
                      <span className="text-white font-thicboi text-xl leading-tight max-w-[500px]">
                        {faq.question}
                      </span>
                      <img
                        src={plus}
                        className={`h-8 transform transition-transform duration-300  ${
                          expandedIndex === index ? "rotate-45" : "rotate-0"
                        }`}
                        alt="Toggle"
                      />
                    </div>
                    {expandedIndex === index && (
                      <div className="px-6 py-4 text-white text-lg font-light">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Right FAQ Section */}
              <div className="flex flex-col gap-4">
                {faq2Data.map((faq, index) => (
                  <div
                    key={index}
                    className=" border-b-[1px] border-[#7D89B0] cursor-pointer"
                    onClick={() => toggleExpand2(index)}
                  >
                    <div className="flex gap-4 p-6 justify-between items-center">
                      <span className="text-white font-thicboi text-xl leading-tight max-w-[500px]">
                        {faq.question}
                      </span>
                      <img
                        src={plus}
                        className={`h-8 transform transition-transform duration-300 ${
                          expandedIndex2 === index ? "rotate-45" : "rotate-0"
                        }`}
                        alt="Toggle"
                      />
                    </div>
                    {expandedIndex2 === index && (
                      <div className="px-6 py-4 text-white text-lg font-light">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-center space-x-4">
            <WhiteButton text={"Request a demo"} to={"/demo"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
