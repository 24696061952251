import React from "react";
import instagram from "../images/instagram.png";
import linkedin from "../images/linkedin.png";
import twitter from "../images/twitter.png";
import WhiteButton from "../components/WhiteButton";
import Gradiantlogo from "../images/Vector.png";
import footer from "../images/footer.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 1)), url(${footer})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="z-20 relative px-6  md:px-40 py-10 md:py-32 flex flex-col gap-48"
      >
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-4 items-center px-10 md:px-40">
            <span className=" text-white font-thicboi font-bold text-3xl md:text-5xl leading-10 text-center">
              The future is happening
            </span>
            <p className="text-[#B9C0D4] font-thicboi font-thin text-base  text-center">
              The future of human capital will be led by technology. Technology
              that can identify hidden talent, that can manage performances and
              take decisions objectively. AI is making big strides with 72% of
              global leaders already working on implementing AI agents to make
              life better. Join the revolution as we work towards making the
              future of work happen the right way!
            </p>
          </div>
          <div className="flex justify-center">
            <div className="bg-[#0E101B] p-2 flex items-center gap-2 border-[1px] rounded-md border-[#30374F]">
              <div>
                <input
                  placeholder="Enter your email address"
                  className="font-thicboi bg-[#0E101B] p-2 w-full text-base  text-[#7D89B0] placeholder-gray-500 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none"
                />
              </div>

              <div className="">
                <WhiteButton text={"Request a demo"} to={"/demo"} />
              </div>
            </div>
          </div>
        </div>

        <div className="relative hidden md:flex justify-center ">
          <img src={Gradiantlogo} alt="logo" />
          <div
            style={{
              position: "absolute",
              top: "65%",
              left: 0,
              width: "100%",
              height: "70%",
              backdropFilter: "blur(10px)",
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
            }}
          />
        </div>
      </div>

      <div className="bg-black flex flex-col gap-20 px-6 md:px-40 py-10 md:py-20">
        <div className="border-b-[1px] border-[#30374F] pb-9 flex flex-col  md:flex-row   justify-between gap-6">
          <div className="flex items-center justify-center md:justify-start">
            <img src={Gradiantlogo} alt="logo" className="h-9"></img>
          </div>
          <div className="flex justify-center items-center gap-6">
            <span className="font-thicboi font-extralight text-xs text-[#B9C0D4]">
              Follow Us On Social Media
            </span>
            <div className="flex gap-3 ">
              {/* <div>
                 <img src={instagram}></img>
                </div> */}
              <div>
                <Link to="https://www.linkedin.com/company/northstarz-ai/">
                  <img src={linkedin}></img>
                </Link>
              </div>
              {/* <div>
<img src={twitter}></img>
                </div> */}
            </div>
          </div>
        </div>
        <div className=" flex flex-col md:flex-row justify-between gap-4">
          <div className="flex flex-col items-center md:items-start gap-6">
            <div className="font-thicboi font-extralight text-base text-[#5D6B98]">
              Northstarz.Ai
            </div>
            <Link to="/">
              <div className="font-thicboi font-extralight text-[18px] text-white">
                Home
              </div>
            </Link>

            {/* <div className='font-thicboi font-extralight text-[18px] text-white'>
Case Studies
</div> */}
            {/* <Link to="/pricing">
<div className='font-thicboi font-extralight text-[18px] text-white'>
Pricing
</div>
</Link> */}

            <button
              onClick={() => {
                window.location.href = "https://northstarz.ai/app";
              }}
              className="font-thicboi font-extralight text-[18px] text-white"
            >
              Log in
            </button>
          </div>

          <div className="flex flex-col items-center md:items-start gap-6">
            <div className="font-thicboi font-extralight text-base text-[#5D6B98]">
              COMPANY
            </div>
            <Link to="/about">
              <div className="font-thicboi font-extralight text-[18px] text-white">
                About us
              </div>
            </Link>

            <Link to="/demo">
              <div className="font-thicboi font-extralight text-[18px] text-white">
                Contact us
              </div>
            </Link>
            <Link to="/demo">
              <div className="font-thicboi font-extralight text-[18px] text-white">
                Book a demo
              </div>
            </Link>
          </div>
          <div className="flex flex-col items-center md:items-start gap-6">
            <div className="font-thicboi font-extralight text-base text-[#5D6B98]">
              RESOURCES
            </div>
            {/* 
<div className='font-thicboi font-extralight text-[18px] text-white'>
Webinars
</div>
<div className='font-thicboi font-extralight text-[18px] text-white'>
Ebooks
</div> */}
            <Link to="https://nas.io/northstarz.ai-2">
              <div className="font-thicboi font-extralight text-[18px] text-white">
                Community
              </div>
            </Link>
            <Link to="/privacy">
              <div className="font-thicboi font-extralight text-[18px] text-white">
                Privacy Policy
              </div>
            </Link>
            <Link to="/terms">
              <div className="font-thicboi font-extralight text-[18px] text-white">
                Terms of Service
              </div>
            </Link>
          </div>
          {/* <Link to = "https://interviewguru.co/blog/">
<div className='font-thicboi font-extralight text-[18px] text-white'>
Blogs
</div>
</Link> */}
        </div>
      </div>
      <div className="border-t-[1px] border-[#30374F] py-9 px-6 flex flex-col md:flex-row gap-6 justify-between bg-black">
        <div className="flex items-center">
          {/* <span className='font-thicboi font-extralight text-base text-[#5D6B98]'>Made with ❤️ india</span>  */}
        </div>
        <div className="flex justify-center items-center gap-6">
          <span className="font-thicboi font-extralight text-base text-[#5D6B98]">
            @2024 Novelio Technologies Pvt. Ltd. All Rights Reserved.
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
